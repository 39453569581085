<!--
    SET002_settings_textbook
    設定
-->
<script>
import axios from "axios";
import { API_URL_TEXTBOOK_COMPLIANCES } from "../const.js";
import LastAccessDate from "../common/LastAccessDate.js";
import StorageExpiration from "@/common/StorageExpiration";
import Cookie from "../common/Cookie.js";
import Storage from "../common/Storage.js";
import HeaderArea from "./Header.vue";
import SideBar from "@/components/SideBar.vue";
import ErrorModal from "@/components/ErrorModal.vue";
import UpdateAnnouncementModal from "@/components/UpdateAnnouncementModal.vue";
import Methods from "@/common/Methods";

const LeaveType = {
  SUBJECT: "subject",
  LINK: "link",
};

export default {
  components: {
    HeaderArea,
    SideBar,
    UpdateAnnouncementModal,
    ErrorModal,
  },
  data() {
    return {
      termType: Cookie.getOrDefault(Cookie.Key.TERM_TYPE),
      allSubjects: [],
      showToast: false,
      lastTermType: Cookie.getOrDefault(Cookie.Key.TERM_TYPE),
      lastSelectedSubject: Cookie.getOrDefault(Cookie.Key.SELECTED_SUBJECT),
      lastTextbooks: Storage.getJson(Storage.Key.TEXTBOOKS) ?? [],
      lastSelectedTermsList: Storage.getJson(Storage.Key.SELECTED_TERMS) ?? [],
      lastSelectedGradesList: Storage.getJson(Storage.Key.SELECTED_GRADES) ?? [],
      isShowCustomPreventModal: false,
      leaveType: LeaveType.LINK,
      screenNameForLeave: "",
      subjectKeyForLeave: "",
      isGoToNextPage: false,
    };
  },
  created() {
    if(Methods.checkMoveToTop()){
      this.isGoToNextPage = true;
      this.$router.push({ name: "FST001_first" });
      return
    }
    this.setDefaultPreventModalListener();

    // 画面に表示する教科・学年・教科書準拠を取得
    axios
      .get(API_URL_TEXTBOOK_COMPLIANCES)
      .then((response) => {
        for (const subject of response.data.subjects) {
          // 教科書設定区分を取得
          const firstSubjectTextbook = this.lastTextbooks.find(
            (textbook) => textbook.subject === subject.subjectName
          );
          subject.settingType = firstSubjectTextbook
            ? firstSubjectTextbook.settingType
            : "1";

          const isCommon = subject.settingType === "1";

          // 全学年共通の教科書準拠を取得してセット
          if (isCommon) {
            const commonTextbook = this.lastTextbooks.find(
              (textbook) =>
                textbook.subject === subject.subjectName &&
                textbook.textbook !== ""
            );
            const existsTextbook = subject.commonTextbookCompliances.some(
              (textbookCompliance) =>
                textbookCompliance === commonTextbook?.textbook
            );
            subject.selectedCommonTextbookCompliance = commonTextbook && existsTextbook
              ? commonTextbook.textbook
              : "";
          } else {
            subject.selectedCommonTextbookCompliance = "";
          }

          for (const grade of subject.grades) {
            // 学年毎の教科書準拠を取得してセット
            if (isCommon) {
              grade.selectedTextbookCompliance = "";
            } else {
              const gradeTextbook = this.lastTextbooks.find(
                (textbook) =>
                  textbook.subject === subject.subjectName &&
                  textbook.grade === grade.gradeName
              );
              const existsTextbook = grade.textbookCompliances.some(
                (textbookCompliance) =>
                  textbookCompliance === gradeTextbook?.textbook
              );
              grade.selectedTextbookCompliance = gradeTextbook && existsTextbook
                ? gradeTextbook.textbook
                : "";
            }
          }

          this.allSubjects.push(subject);
        }
      })
      .catch((error) => {
        console.log(error);
        this.openErrorModal(error.response.status);
      });
  },
  mounted() {
    StorageExpiration.check();
    this.$refs.updateAnnouncementModal.checkUpdate();
    LastAccessDate.update();

    this.setCustomPreventModalListener();
  },
  beforeRouteLeave(to, from, next) {
    if (this.isGoToNextPage) {
      next();
      return;
    }

    if(this.isChanged) {
      if(window.confirm('入力内容が保存されない可能性があります。ページを離れますか？')) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  unmounted() {
    window.onbeforeunload = null;
  },
  computed: {
    /**
     * 各教科の全学年共通のセレクトボックスおよび学年毎のセレクトボックスのうち、
     * いずれか1つ以上「未選択」以外の値が選択されていれば保存ボタンを有効化する
     */
    cannotSave: function () {
      let isSelected = false;

      const subjects = this.allSubjects;
      for (const subject of subjects) {
        const isCommon = subject.settingType === "1";

        if (isCommon) {
          // 全学年共通の場合
          const selectedTextbookCompliance = subject.selectedCommonTextbookCompliance;
          if (selectedTextbookCompliance !== "") {
            isSelected = true;
            break;
          }
        } else {
          // 学年毎の場合
          for (const grade of subject.grades) {
            if (grade.selectedTextbookCompliance !== "") {
              isSelected = true;
              break;
            }
          }
        }
      }
      return !isSelected;
    },

    /**
     * ブラウザに保存する教科書準拠データを取得
     */
    textbooks() {
      const textbooks = [];
      const subjects = this.allSubjects;

      // セットする教科書準拠・学期データを作成
      for (const subject of subjects) {
        const isCommon = subject.settingType === "1";

        // 学年ごとにループ
        for (const grade of subject.grades) {
          if (!grade.selectable) {
            continue;
          }

          // 教科書準拠データを作成
          if (isCommon) {
            // 全学年共通の場合
            textbooks.push({
              subject: subject.subjectName,
              settingType: subject.settingType,
              grade: grade.gradeName,
              textbook: subject.selectedCommonTextbookCompliance,
            });
          } else {
            // 学年毎の場合
            textbooks.push({
              subject: subject.subjectName,
              settingType: subject.settingType,
              grade: grade.gradeName,
              textbook: grade.selectedTextbookCompliance,
            });
          }
        }
      }
      return textbooks;
    },

    /**
     * ブラウザに保存する選択中の教科を取得
     */
    selectedSubject() {
      const textbooks = this.textbooks;

      // 最後に選択した教科に教科書準拠が設定されている場合はその教科を返す
      const existsLastSubjectTextbook = textbooks.some(
        (textbook) =>
          textbook.subject === this.lastSelectedSubject &&
          textbook.textbook !== ""
      );
      if (existsLastSubjectTextbook) {
        return this.lastSelectedSubject;
      }

      for (const textbook of textbooks) {
        if (textbook.textbook !== "") {
          return textbook.subject;
        }
      }
      return "";
    },

    /**
     * ブラウザに保存する選択中の学期データを取得
     */
    selectedTermsList() {
      // 最後に選択した学期制から変更がない場合は、選択中の学期をそのまま帰す
      if (this.termType === this.lastTermType) {
        if (this.lastSelectedTermsList.length > 0) {
          return this.lastSelectedTermsList;
        }
      }

      const termsList = [];
      const subjects = this.allSubjects;

      for (const subject of subjects) {
        if (this.termType === "3") {
          termsList.push({
            subject: subject.subjectName,
            terms: [1, 2, 3],
          });
        } else if (this.termType === "2") {
          termsList.push({
            subject: subject.subjectName,
            terms: [1, 2],
          });
        }
      }
      return termsList;
    },

    /**
     * ブラウザに保存する選択中の学年データを取得
     */
    selectedGradesList() {
      const selectedGradesList = [];

      const subjects = this.allSubjects;
      const textbooks = this.textbooks;

      // 今回教科書準拠が設定されている学年のみ選択中の学年として返す
      for (const subject of subjects) {
        const selectedGrades = [];

        for (const grade of subject.grades) {
          const existsTextbook = textbooks.some(
            (textbook) =>
              textbook.subject === subject.subjectName &&
              textbook.grade === grade.gradeName &&
              textbook.textbook !== ""
          );
          if (!existsTextbook) {
            continue;
          }

          selectedGrades.push(grade.gradeName);
        }

        selectedGradesList.push({
          subject: subject.subjectName,
          grades: selectedGrades,
        });
      }
      return selectedGradesList;
    },

    /**
     * 前回の保存から変更があるかどうかを判定
     */
    isChanged() {
      const isChangedTermType = this.termType !== this.lastTermType;
      const isChangedTextbooks = JSON.stringify(this.textbooks) !== JSON.stringify(this.lastTextbooks);

      return isChangedTermType || isChangedTextbooks;
    },
  },
  methods: {
    /**
     * リンクがクリックされた時の処理
     */
    onLinkClicked(screenName) {
      this.leaveType = LeaveType.LINK;
      this.screenNameForLeave = screenName;

      if (!this.isChanged) {
        this.leave();
        return;
      }
      this.openConfirmModal();
    },

    /**
     * 教科がクリックされた時の処理
     */
    onSubjectClicked(subjectKey) {
      this.leaveType = LeaveType.SUBJECT;
      this.subjectKeyForLeave = subjectKey;

      if (!this.isChanged) {
        this.leave();
        return;
      }
      this.openConfirmModal();
    },

    /**
     * 確認モーダル表示処理
     */
    openConfirmModal() {
      this.isShowCustomPreventModal = true;
    },

    /**
     * ブラウザ標準離脱防止モーダルのイベントリスナー設定
     */
    setDefaultPreventModalListener() {
      window.onbeforeunload = this.showDefaultPreventModal;
    },

    /**
     * カスタム離脱防止モーダルのイベントリスナー設定
     */
    setCustomPreventModalListener() {
      const settingModal = document.getElementById("modal-setting");
      if (!settingModal) {
        return;
      }

      const triggerBtn = document.querySelectorAll('[data-modal="modal-setting"]');
      triggerBtn.forEach((openBtn) => {
        openBtn.addEventListener("click", () => {
          // ブラウザ標準の離脱モーダル表示をOFF
          window.onbeforeunload = null;

          const unloadBtn = document.getElementById("unloadBtn");
          unloadBtn.addEventListener("click", () => {
            const href = openBtn.children[0].getAttribute("href");
            window.location.href = href;
          });

          // ブラウザ標準の離脱モーダル表示をON
          window.onbeforeunload = this.showDefaultPreventModal;
        });
      });
    },

    /**
     * ブラウザ標準の離脱防止モーダルを表示
     */
    showDefaultPreventModal(event) {
      if (!this.isChanged) {
        return;
      }
      event.preventDefault();
      event.returnValue = "";
    },

    /**
     * ページから離脱する場合の処理
     */
    leave() {
      this.closePreventModal();
      this.isGoToNextPage = true;

      if (this.leaveType === LeaveType.SUBJECT) {
        this.restore();
        this.$refs.sideBar.switchSubject(this.subjectKeyForLeave);
        return;
      }
      this.$router.push({ name: this.screenNameForLeave });
    },

    /**
     * 離脱防止モーダルを閉じる
     */
    closePreventModal() {
      this.isShowCustomPreventModal = false;
    },

    /**
     * リセットボタン押下時の処理
     */
    reset() {
      // 学期制リセット
      this.termType = "3";

      // 教科書設定リセット
      for (const subject of this.allSubjects) {
        subject.settingType = "1";
        subject.selectedCommonTextbookCompliance = "";
        for (const grade of subject.grades) {
          grade.selectedTextbookCompliance = "";
        }
      }
    },

    /**
     * 前回の状態を復元する
     */
    restore() {
      this.termType = this.lastTermType;

      const lastTextbooks = this.lastTextbooks;

      for (const subject of this.allSubjects) {
        const textbooks = lastTextbooks.filter(
          (textbook) => textbook.subject === subject.subjectName
        );

        // 教科書設定区分を復元
        subject.settingType = textbooks[0].settingType;

        const isCommon = subject.settingType === "1";

        if (isCommon) {
          // 全学年共通の教科書準拠を復元
          subject.selectedCommonTextbookCompliance = textbooks[0].textbook;
          for (const grade of subject.grades) {
            grade.selectedTextbookCompliance = "";
          }
          continue;
        }

        // 学年毎の教科書準拠を復元
        subject.selectedCommonTextbookCompliance = "";
        for (const grade of subject.grades) {
          const textbook = textbooks.find(
            (textbook) => textbook.grade === grade.gradeName
          );
          // 対応していない学年の場合はスキップ
          if(!textbook){
            continue;
          }
          grade.selectedTextbookCompliance = textbook.textbook;
        }
      }
    },

    /**
     * 保存ボタン押下時の処理
     */
    save() {
      // 学期制セット
      Cookie.set(Cookie.Key.TERM_TYPE, this.termType);

      // 教科書設定セット
      const textbooks = this.textbooks;
      Storage.setJson(Storage.Key.TEXTBOOKS, textbooks);

      // 選択中の教科セット
      const selectedSubject = this.selectedSubject;
      Cookie.set(Cookie.Key.SELECTED_SUBJECT, selectedSubject);

      // 選択中の学期セット
      const selectedTermsList = this.selectedTermsList;
      Storage.setJson(Storage.Key.SELECTED_TERMS, selectedTermsList);

      // 選択中の学年セット
      const selectedGradesList = this.selectedGradesList;
      Storage.setJson(Storage.Key.SELECTED_GRADES, selectedGradesList);

      // トースト表示
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 2000);

      // 前回の値を更新
      this.lastTermType = Cookie.getOrDefault(Cookie.Key.TERM_TYPE);
      this.lastSelectedSubject = Cookie.getOrDefault(Cookie.Key.SELECTED_SUBJECT);
      this.lastTextbooks = Storage.getJson(Storage.Key.TEXTBOOKS) ?? [];
      this.lastSelectedTermsList = Storage.getJson(Storage.Key.SELECTED_TERMS) ?? [];
      this.lastSelectedGradesList = Storage.getJson(Storage.Key.SELECTED_GRADES) ?? [];
    },

    /**
     * エラーモーダルを開く
     */
    openErrorModal(statusCode) {
      this.$refs.errorModal.open(statusCode);
    },
  },
};
</script>

<template>
  <div class="l-wrapper">
    <HeaderArea
      :logoRedFlag="true"
      :logoSideFlag="true"
      :menuFlag="true"
      :emitLinkClicked="onLinkClicked"
      @openErrorModal="openErrorModal"
    />

    <div class="l-contents settings">
      <SideBar
        ref="sideBar"
        :isShowSubjectOnly="true"
        :isActiveSettings="true"
        :emitSubjectClicked="onSubjectClicked"
        @openErrorModal="openErrorModal"
      />

      <main class="l-common-contents__asdc-less setting">
        <div>
          <div class="l-common-contents__body setting" data-background="gray">
            <div class="p-common-contents__body-all__head">
              <div
                class="p-common-contents__body-all__head__setting-title__container mgb-pc--40"
              >
                <div class="p-common-contents__body-all__head__title-upper">
                  設定
                </div>
                <div
                  class="p-common-contents__body-all__head__reset__container"
                >
                  <button
                    type="button"
                    class="p-common-contents__body-all__head__reset c-txt"
                    @click="reset()"
                  >
                    <span>リセット</span>
                  </button>
                  <button
                    type="button"
                    @click="save()"
                    class="c-btn c-btn--m c-btn--bg-primary c-txt"
                    :class="{ disabled: cannotSave }"
                  >
                    <span id="save">設定を保存</span>
                  </button>
                </div>
              </div>
              <div
                class="p-common-contents__body-all__head__title c-txt--xl mgb-pc--30"
              >
                学期制設定
              </div>
              <div class="p-common-contents__body-all__head__container-btn">
                <label class="p-common-contents__body-all__head__btn c-txt--xs">
                  <input
                    type="radio"
                    v-model="termType"
                    value="3"
                  />
                  ３学期制
                </label>
                <label class="p-common-contents__body-all__head__btn c-txt--xs">
                  <input
                    type="radio"
                    v-model="termType"
                    value="2"
                  />
                  ２学期制
                </label>
              </div>
            </div>
            <div class="p-common-contents__body-all__body">
              <div class="p-common-contents__body-all__body__title c-txt--xl">
                教科書設定
              </div>

              <div
                class="p-common-contents__body-all__body__selects__container p-common-contents__body-all__body__selects__container-open"
              >
                <div
                  class="p-common-contents__body-all__body__select__container"
                  v-for="subject in allSubjects"
                  :key="subject.subjectName"
                >
                  <div
                    class="p-common-contents__body-all__body__select__container-left"
                  >
                    <span
                      class="p-common-contents__body-all__body__select-subject"
                    >
                      {{ subject.subjectName }}
                    </span>
                    <div
                      class="p-common-contents__body-all__body__container-btn"
                    >
                      <label
                        class="p-common-contents__body-all__body__btn c-txt--xs"
                      >
                        <input
                          type="radio"
                          v-model="subject.settingType"
                          value="1"
                          class="js-selectUnity"
                        />
                        全学年共通
                      </label>
                      <label
                        class="p-common-contents__body-all__body__btn c-txt--xs"
                      >
                        <input
                          type="radio"
                          v-model="subject.settingType"
                          value="2"
                          class="js-selectSeparate"
                        />
                        学年毎に設定
                      </label>
                    </div>
                  </div>
                  <div
                    class="p-common-contents__body-all__body__select__container-right"
                  >
                    <div
                      class="p-common-contents__body-all__body__book white js-unity"
                    >
                      <select
                        v-model="subject.selectedCommonTextbookCompliance"
                        class="c-txt--s"
                      >
                        <option value="">未選択</option>
                        <option
                          v-for="textbookCompliance in subject.commonTextbookCompliances"
                          :key="textbookCompliance"
                          :value="textbookCompliance"
                        >
                          {{ textbookCompliance }}
                        </option>
                      </select>
                    </div>
                    <div
                      class="p-common-contents__body-all__body__container-books js-separate"
                    >
                      <div
                        class="p-common-contents__body-all__body__book white"
                        v-for="grade in subject.grades"
                        :key="grade.gradeName"
                      >
                        <span
                          class="p-common-contents__body-all__body__book-num c-txt--xxs"
                        >
                          {{ grade.gradeName }}
                        </span>
                        <select
                          v-if="grade.selectable"
                          v-model="grade.selectedTextbookCompliance"
                          class="c-txt--s"
                        >
                          <option value="">未選択</option>
                          <option
                            v-for="textbookCompliance in grade.textbookCompliances"
                            :key="textbookCompliance"
                            :value="textbookCompliance"
                          >
                            {{ textbookCompliance }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="p-common-contents__serial__save"
              :class="{ appear: showToast }"
            >
              <img src="/assets/img/common/icon_check-black.svg" alt="" />
              <p class="c-txt--xxs">設定を保存しました</p>
            </div>
          </div>
        </div>
        <!-- l-common-contents__maskにis-appearクラスを付与することで表示される -->
        <div class="l-common-contents__mask" :class="{ 'is-appear': isShowCustomPreventModal }" id="modal-setting">
          <div class="c-modal setting-confirm">
            <div class="c-modal__head setting-confirm">
              <div class="c-modal__title setting-confirm">
                設定を保存せずに移動しますか？
              </div>
            </div>
            <div class="c-modal__body setting-confirm">
              <p class="p-setting-confirm__txt c-txt">
                変更内容は破棄されます。
              </p>
            </div>
            <div class="c-modal__foot">
              <button
                id="unloadBtn"
                type="button"
                class="c-btn c-btn--l c-btn--bg-secondary c-txt--l c-btn__txt"
                @click="leave()"
              >
                <span>保存せずに移動</span>
              </button>
              <button
                type="button"
                class="c-btn c-btn--l c-btn--bg-primary c-txt--l modalClose"
                @click="closePreventModal()"
              >
                <span>キャンセル</span>
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
    <!-- [/l-contents] -->
  </div>
  <!-- [/l-wrapper] -->

  <UpdateAnnouncementModal ref="updateAnnouncementModal" />
  <ErrorModal ref="errorModal" />
</template>
