<!--
    PRV001_contents_preview_pdf.vue
    教材プレビュー_PDF単体
 -->
<script>

import { FILE_EXTENSION_MOVIE, FILE_EXTENSION_PDF, API_URL } from "../const.js";
import Storage from "../common/Storage.js";
import Methods from "../common/Methods.js";
import axios from "axios";
import saveAs from "file-saver";
import LastAccessDate from "../common/LastAccessDate.js";
import StorageExpiration from "@/common/StorageExpiration";
import UpdateAnnouncementModal from "@/components/UpdateAnnouncementModal.vue";
import ErrorModal from "@/components/ErrorModal.vue";
import ContentFile from "../common/ContentFile.js";

export default {
    name: "previewMultiple",
    components: {
        UpdateAnnouncementModal,
        ErrorModal,
    },
    data() {
        return {
            // 右枠の表示対象データ
            displayData: [],
            // 左枠のプレビュー一覧
            previewList: [],
            // プレビュー表示中のファイルが動画タイプかどうか
            isPreviewMovie: false,
            // プレビュー表示中のファイルがExcelタイプかどうか
            isPreviewExcel: false,
            // 印刷ボタンの押下可否
            isPrintDisabled: true,
            // ダウンロードボタンの押下可否
            isDownloadDisabled: true,
            // 左枠選択中のコンテンツID群
            selectedList: [],
            // ドラッグ&ドロップ中か判別用
            isDragging: false,
            // サイトメニューのスワイプ中に選択したインデックスを保持する
            savedIndex: -1,
        };
    },
    mounted: function () {
        if(Methods.checkMoveToTop()){
            this.$router.push({ name: "FST001_first" })
            return
        }
        StorageExpiration.check();
        this.$refs.updateAnnouncementModal.checkUpdate();
        LastAccessDate.update();
        // パラメータに従い、コンテンツ情報を取得
        this.callContentsPreviewApi();
    },
    methods: {
        // プレビュー対象の情報を取得する
        callContentsPreviewApi() {

            // 遷移先からのパラメータ取得処理
            const param = Storage.getPreviewListContents();
            if (param.length == 0) {
                // パラメータがない場合は500エラーにする
                this.$router.push({
                    name: 'ERR001_error',
                });
                return;
            }

            // データ取得
            const apiUrl = API_URL + "/contents/preview";
            axios.post(apiUrl, param)
                .then((res) => {
                    // 児童・教師用でcontentIdが重複するのでチェックボックス用にデータを加工
                    const dispList = [];
                    res.data.forEach((value) => {
                        let wrk = value;
                        wrk.checkBoxId = (value.forStudent ? 'S' : 'T') + value.contentId;
                        dispList.push(wrk);
                    });
                    // 左のプレビュー一覧をセット
                    this.previewList = dispList;
                    if (this.previewList.length > 0) {
                        // プレビューするデータは先頭を指定する
                        this.selectContent(0);
                        // nextTickでDOMが更新されるのを待つ
                        this.$nextTick(() => {
                            // 選択状態になっているコンテンツを取得
                            const focusItem = document.querySelector('.p-preview-aside__img');
                            if (focusItem != null) {
                                focusItem.classList.add('is-focus');
                            }
                        });
                    }
                    // 遷移時にチェック状態にしたいので、selectedListに格納する
                    this.selectedList = this.previewList.map((value) => {
                        return value.checkBoxId;
                    });
                    // チェックされているコンテンツによって印刷ボタン・ダウンロードボタンの押下可否を切り替える
                    this.changePrintButtonDisabled();
                    this.changeDownloadButtonDisabled();
                })
                .catch((error) => {
                    console.log("error: " + error);
                    this.openErrorModal(error.response.status);
                });
        },
        // プレビュー一覧のチェックボックスクリック時
        changeSelectedPreview() {
            this.changePrintButtonDisabled();
            this.changeDownloadButtonDisabled();
        },
        // 選択削除ボタン押下時
        clearSelectedContents() {
            this.selectedList = [];
            this.changePrintButtonDisabled();
            this.changeDownloadButtonDisabled();
        },
        // [SUB]印刷ボタン押下可否判定
        changePrintButtonDisabled() {
            if (this.selectedList.length == 0) {
                this.isPrintDisabled = true;
                return;
            }

            // 選択中ファイルがPDFファイルかどうかを拡張子で表示できるか判断する
            let isExpectPdf = false;
            this.selectedList.forEach((value) => {
                var wrk = this.previewList.filter(e => e.checkBoxId == value);
                if (wrk == undefined || wrk.length == 0) {
                    return;
                }
                // ダウンロード対象のファイルがPDFファイルかどうかを拡張子で判断する
                let fileExtension = Methods.getFileExtension(wrk[0].contentFileUrl);
                if (fileExtension !== FILE_EXTENSION_PDF) {
                    isExpectPdf = true;
                    return true;
                }
            });
            // PDFファイル以外が選択中ならDisabledにする
            this.isPrintDisabled = isExpectPdf;
        },
        // [SUB]ダウンロードボタン押下可否判定
        changeDownloadButtonDisabled() {
            this.isDownloadDisabled = (this.selectedList.length == 0);
        },
        // サイドメニュースワイプ時に保持しているインデックスをリセットする
        resetIndex() {
            this.savedIndex = -1;
        },
        // 右プレビューの表示切り替え(倍率は保持されない)
        selectContent(index,event) {
            // スワイプ中であれば何もしない（iPad Safari対応）
            if(index < 0) {
                this.savedIndex = -1;
                return;
            }
            // 選択状態になっているコンテンツを取得
            const focusItem = document.querySelector('.p-preview-aside__img.is-focus');
            if (focusItem != null) {
                focusItem.classList.remove('is-focus');
            }
            // イベントが存在する場合は選択状態にする(初期表示時はイベントがないため、ここで判定)
            if (event != undefined){
                event.currentTarget.classList.add('is-focus');
            }
            this.displayData = this.previewList[index];
            // ファイルの拡張子によって下部のガイダンスの表示を切り替え
            let fileExtension = Methods.getFileExtension(this.displayData.contentFileUrl);
            this.isPreviewMovie = (fileExtension === FILE_EXTENSION_MOVIE);
            this.isPreviewExcel = ContentFile.isExcel(
                this.displayData.contentFileUrl
            );
            // 画像の縮尺をリセット
            window.instance.moveTo(0, 0);
            window.instance.zoomAbs(0, 0, 1);
        },
        // 印刷ボタン押下
        printContent() {
            if (this.selectedList.length === 0) {
                // 理論上はあり得ない
                return;
            }
            // PDFファイル連結API送信
            this.callCreatePdfApi();
        },
        // ダウンロードボタン押下
        downloadContent() {
            if (this.selectedList.length === 0) {
                // 理論上はあり得ない
                return;
            }
            // ZIPファイル連結API送信
            this.callContentsDownloadApi();
        },
        // マウスダウン時
        handleDragStart() {
            this.isDragging = false;
        },
        // マウス移動時
        handleDragging() {
            this.isDragging = true;
        },
        handleClick() {
            if(!this.isDragging) {
                this.showContent();
            }
        },
        // コンテンツファイルの取得
        showContent() {
            // プレビュー中ファイルの拡張子を取得
            let fileExtension = Methods.getFileExtension(this.displayData.contentFileUrl);
            if (fileExtension === false) {
                return;
            } else if (fileExtension === FILE_EXTENSION_MOVIE) {
                // 動画プレビュー画面に遷移
                let nextRoot = this.$router.resolve({
                    name: 'PRV002_contents_preview_video',
                });
                Storage.setVideoPreviewContents([this.displayData]);
                Methods.openTab(nextRoot.href);
            } else if (fileExtension === FILE_EXTENSION_PDF) {
                // 別タブでコンテンツを表示する
                let url = this.displayData.contentFileUrl;
                Storage.addContentsIdToLocalstrage(this.displayData.contentId);
                Methods.openTab(url);
            } else {
                // ファイルダウンロード
                const params = {
                    "contents": [
                        {
                            "contentId": this.displayData.contentId,
                            "forStudent": this.displayData.forStudent
                        }
                    ]
                };
                
                const apiUrl = API_URL + "/contents/download";
                axios.post(apiUrl, params, { responseType: "blob" })
                    .then((res) => {
                        // 実行結果のバイナリデータをそのままファイルにする
                        const mimeType = res.headers["content-type"];
                        const name = this.getDownloadFileName(res.headers["content-disposition"]);
                        const blob = new Blob([res.data], { type: mimeType });
                        saveAs(blob, name);
                        // ダウンロード後、このコンテンツIDを使用済みとして保存する
                        Storage.addContentsIdToLocalstrage(this.displayData.contentId);
                    })
                    .catch((error) => {
                        if (error.message == undefined) {
                            console.log("error: " + error);
                        } else {
                            console.log("error: " + error.message);
                        }
                        this.openErrorModal(error.response.status);
                    });
            }
        },
        // ダウンロード対象のZIP作成要求を行う
        callCreatePdfApi() {
            // パラメータ作成
            const target = this.getSelectedContents();
            const params = {
                "contents": target
            };
            // API送信
            const apiUrl = API_URL + "/pdf/merge";
            axios.post(apiUrl, params)
                .then((res) => {
                    // URLが返ってくるので別タブで表示する
                    if (res.data.pdfFileUrl == undefined || res.data.pdfFileUrl?.length == 0) {
                        return;
                    }
                    let resultUrl = res.data.pdfFileUrl;
                    Methods.openTab(resultUrl);
                    // ダウンロード後、このコンテンツIDを使用済みとして保存する
                    target.forEach((item) => {
                        Storage.addContentsIdToLocalstrage(item.contentId);
                    });
                })
                .catch((error) => {
                    if (error.message == undefined) {
                        console.log("error: " + error);
                    } else {
                        console.log("error: " + error.message);
                    }
                    this.openErrorModal(error.response.status);
                });
        },
        // ダウンロード対象のZIP/PDF作成要求を行う
        callContentsDownloadApi() {
            // パラメータ作成
            const target = this.getSelectedContents();
            const params = {
                "contents": target
            };
            // API送信
            const apiUrl = API_URL + "/contents/download";
            axios.post(apiUrl, params, { responseType: "blob" })
                .then((res) => {
                    // 実行結果のバイナリデータをそのままファイルにする
                    const mineType = res.headers["content-type"];
                    const name = this.getDownloadFileName(res.headers["content-disposition"]);
                    const blob = new Blob([res.data], { type: mineType });
                    saveAs(blob, name);

                    // ダウンロード後、このコンテンツIDを使用済みとして保存する
                    target.forEach((item) => {
                        Storage.addContentsIdToLocalstrage(item.contentId);
                    });
                })
                .catch((error) => {
                    if (error.message == undefined) {
                        console.log("error: " + error);
                    } else {
                        console.log("error: " + error.message);
                    }
                    this.openErrorModal(error.response.status);
                });
        },
        // コンテンツIDからプレビュー対象のデータを取得
        getSelectedContents() {
            let target = [];
            this.selectedList.forEach((value) => {
                var wrk = this.previewList.filter(e => e.checkBoxId == value);
                if (wrk == undefined || wrk.length == 0) {
                    return [];
                }
                let row = {
                    "contentId": wrk[0].contentId,
                    "forStudent": wrk[0].forStudent
                };
                target.push(row)
            });
            return target;
        },
        // content-dispositionからファイル名だけを取得
        getDownloadFileName(value) {
            if (value == undefined) {
                return "";
            }
            const splitted = value.split("filename=");
            if (splitted.length < 2) {
                return '';
            }
            return decodeURI(splitted[1].replace(/"/g, ''));
        },
        // コンテンツURLを分割してファイル名だけ取得する
        splitUrlToFileName(url) {
            if (url == null || url == undefined) {
                return '';
            }
            return url.split('/').pop();
        },

        /**
         * エラーモーダルを開く
         */
        openErrorModal(statusCode) {
            this.$refs.errorModal.open(statusCode);
        },

        /**
         * 右クリックメニュー表示制御
         */
        preventContextMenu(event) {
            event.preventDefault();
        },

        /**
         * mouseover時にホバー状態を付与する
         */
        attachHover(event) {
            // 端末がタッチ（タップ）に対応しているか確認する
            const touch_event = window.ontouchstart;
            const touch_points = navigator.maxTouchPoints;
            const target = event.currentTarget;
            // タッチ対応端末の場合またはis-focusが付与されている場合は何もせずに終了する
            if (
                touch_event !== undefined 
                || 0 < touch_points 
                || target.classList.contains('is-focus')
            ) {
                return;
            }
            target.classList.add('is-hover');
        },

        /**
         * mouseout時にホバー状態を解除する
         */
        removeHover(event) {
            const target = event.currentTarget;
            if (target.classList.contains('is-hover')) {
                target.classList.remove('is-hover');
            }
        },
    },
};
</script>

<template>
    <body class="preview preview-several">
        <div class="l-wrapper">
            <div class="p-preview-aside">
                <div class="p-preview-aside__wrapper">
                <ul class="p-preview-aside__list">
                    <li class="p-preview-aside__item" v-for="(value, index) in previewList" :key="index">
                        <div
                            class="p-preview-aside__img"
                            @click="selectContent(index,$event)"
                            @touchstart="savedIndex = index"
                            @touchmove="resetIndex()"
                            @touchend="selectContent(savedIndex,$event)"
                            @mouseover="attachHover($event)"
                            @mouseout="removeHover($event)"
                            @contextmenu="preventContextMenu($event)"
                        >
                            <label class="c-checkbox preview">
                                <input
                                    type="checkbox"
                                    name="児童用"
                                    class="c-checkbox__input"
                                    :value="value.checkBoxId"
                                    v-model="selectedList"
                                    @change="changeSelectedPreview()"
                                />
                                <span class="c-checkbox__txt"></span>
                            </label>
                            <div class="p-preview-aside__img-wrapper">
                                <img
                                    :src="value.thumbnailUrl ? value.thumbnailUrl : '/assets/img/common/dummy/noimage.png'"
                                    onerror="this.src='/assets/img/common/dummy/noimage.png';"
                                    alt=""
                                />
                            </div>
                            <div class="p-preview-aside__img-title">
                                <p class="p-preview-aside__img-text">
                                    {{ value.materialName }} {{ value.description }}
                                </p>
                            </div>
                        </div>
                    </li>
                </ul>
                </div>
                <div class="p-preview-aside__oper">
                    <div class="p-preview-aside__contaier__select">
                        <div class="p-preview-aside__select__txt c-txt--s">
                            <span id="checkedNumber">{{ selectedList.length }}</span>
                            件を選択中
                        </div>
                        <button
                            class="p-preview-aside__btn"
                            id="releaseBtn"
                            @click="clearSelectedContents()"
                        >
                        選択解除</button>
                    </div>
                    <button
                        class="p-preview-aside__btn__copy"
                        v-bind:class="{ 'disabled' : isPrintDisabled }"
                        :disabled="isPrintDisabled"
                        @click="printContent()"
                    >
                        <img
                            src="/assets/img/common/icon_tab_blank-white.svg"
                            alt=""
                        />
                        <span>開く</span>
                    </button>
                    <button
                        class="p-preview-aside__btn__save"
                        v-bind:class="{ 'disabled' : isDownloadDisabled }"
                        :disabled="isDownloadDisabled"
                        @click="downloadContent()"
                    >
                        <img
                            src="/assets/img/preview/icon_save_small.svg"
                            alt=""
                        />
                        <span>ダウンロード</span>
                    </button>
                </div>
            </div>

            <main>
                <div class="l-header-preview">
                    <div class="l-header-preview-several__container">
                        <div class="l-header-preview__title c-txt--xl">
                            {{ splitUrlToFileName(displayData.contentFileUrl) }}
                        </div>
                        <ul>
                            <li>
                                <button class="zoom-out__btn">
                                    <img
                                        src="/assets/img/preview/icon_minus-white.svg"
                                        alt=""
                                    />
                                </button>
                            </li>
                            <li>
                                <button class="zoom-in__btn">
                                    <img
                                        src="/assets/img/preview/icon_plus-white.svg"
                                        alt=""
                                    />
                                </button>
                            </li>
                            <li>
                                <button class="zoom-back__btn">
                                    <img
                                        src="/assets/img/preview/icon_fit-white.svg"
                                        alt=""
                                    />
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="p-preview__area">
                    <div class="p-preview__thumbnail-area">
                        <div
                            class="p-preview__thumbnail-area"
                            id="zoom"
                        >
                            <img
                                class="p-preview__thumbnail"
                                :src="displayData.thumbnailUrl ? displayData.thumbnailUrl : '/assets/img/common/dummy/noimage.png'"
                                onerror="this.src='/assets/img/common/dummy/noimage.png';"
                                alt="プレビュー画像"
                                @touchstart="handleDragStart()"
                                @touchmove="handleDragging()"
                                @touchend="handleClick()"
                                @mousedown="handleDragStart()"
                                @mousemove="handleDragging()"
                                @mouseup="handleClick()"
                            />
                        </div>
                    </div>
                </div>
                <div class="l-footer-preview" v-if="isPreviewMovie">
                    <div class="l-footer-preview__title">
                        クリックすると、動画を再生できるページが開きます。
                    </div>
                </div>
                <div class="l-footer-preview" v-if="isPreviewExcel">
                    <div class="l-footer-preview__title">
                        クリックすると、対象ファイルをダウンロードします。
                    </div>
                </div>
            </main>
        </div>
        <!-- [/l-wrapper] -->

        <UpdateAnnouncementModal ref="updateAnnouncementModal" />
        <ErrorModal ref="errorModal" />

    </body>
</template>

<script setup>
// DB.jsで読み込んだデータを取得
//import DB from '../DB.js'
</script>
