<!--
  InformationDetailModal.vue
  お知らせ詳細モーダル
-->
<script>
export default {
  name: "InformationDetailModal",
  data() {
    return {
      isShow: false,
      information: {
        informationTypeName: "",
        displayDate: "",
        title: "",
        body: "",
      },
    };
  },
  mounted() {
    const infoModalCover = document.getElementById('info_modal_cover');
    // モーダル枠外を押下された場合はモーダルを閉じる
    infoModalCover.addEventListener('click', (event) => {
      if (event.target.closest('#info_modal') === null) {
        this.close();
      }
    });
  },
  methods: {
    open(information) {
      this.information = information;
      this.isShow = true;
    },
    close() {
      const infoModal = document.querySelector('.c-modal__wrapper');
      // スクロール位置を0にする
      infoModal.scrollTop = 0;
      this.isShow = false;
    },
  },
};
</script>

<template>
  <div
    class="l-common-contents__mask modal"
    :class="{ 'is-appear': isShow }"
    id="info_modal_cover"
  >
    <div class="c-modal info" id="info_modal">
      <div class="c-modal__wrapper">
        <div class="c-modal__head">
          <span class="p-info__type c-txt--xs">{{ information.informationTypeName }}</span>
          <span class="p-info__date c-txt--s">{{ information.displayDate }}</span>
          <div class="c-modal__title info">
            {{ information.title }}
          </div>
        </div>
        <div class="c-modal__body">
          <p
            class="p-info__txt c-txt"
            style="white-space: pre-wrap; word-wrap: break-word"
            v-html="information.body"
          />
        </div>
      </div>
      <div class="c-modal__foot">
        <button
          type="button"
          @click="close()"
          class="c-btn c-btn--l c-btn--bg-primary c-txt--l"
        >
          <span>閉じる</span>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
:deep(.p-info__txt.c-txt a) {
  color: #1a8bf3;
  text-decoration: underline;
  cursor: pointer;
}
:deep(.p-info__txt.c-txt a:hover) {
  color: #1a8bf3;
  text-decoration: none;
}
</style>
