<!--
    MTR001_materials.vue
    教材一覧
 -->
<template>
  <body data-category="national-language">
    <div class="l-wrapper">
      <HeaderArea
        :logoRedFlag="true"
        :logoSideFlag="true"
        :menuFlag="true"
        @openErrorModal="openErrorModal"
      />
      <div class="l-contents teaching-unit">
        <SideBar
          v-on:getData="jsonData"
          @openErrorModal="openErrorModal"
        />
        <main
          class="l-common-contents"
          v-if="this.materialTypes.length === 0 && !searching"
        >
          <div class="l-common-contents__body" data-background="gray">
            <div class="l-common-contents__body__error">
              <p
                class="p-common-contents__body__error"
                v-html="htmlText(message)"
              ></p>
            </div>
          </div>
        </main>

        <main class="l-common-contents" v-else>
          <div class="l-common-contents__body" data-background="gray">
            <div class="p-teaching-unit-list__body">
              <div class="p-common-contents__content-outer">
                <div
                  class="p-common-contents__container-material"
                  v-for="materialType in this.materialTypes"
                  :key="materialType.Id"
                >
                  <router-link
                    :to="{
                      name: 'MTR002_materials_contents',
                      params: { materialTypeId: materialType.materialTypeId },
                    }"
                  >
                    <div
                      class="p-common-contents__material-img"
                      v-if="materialType.imageUrl != ''"
                    >
                      <div class="p-common-contents__material-img-innerframe">
                        <img v-bind:src="materialType.imageUrl" alt="" />
                      </div>
                    </div>
                    <div class="p-common-contents__material-img" v-else>
                      <img src="/assets/img/common/dummy/noimage.png" alt="" />
                    </div>
                    <div class="p-common-contents__material-content-m">
                      <p
                        class="teaching-material-list__material__title c-txt--l"
                      >
                        {{ materialType.materialName }}
                      </p>

                      <p
                        class="teaching-material-list__material__txt c-txt--xs"
                      >
                        {{ materialType.description }}
                      </p>
                      <div class="teaching-material-list__material__recommend">
                        <p
                          class="teaching-material-list__material__recommend-title"
                        >
                          オススメの使い方：
                        </p>
                        <span
                          class="teaching-material-list__material__recommend-method"
                          v-for="materialIcon in materialType.materialIcons"
                          :key="materialIcon"
                        >
                          {{ materialIcon }}
                        </span>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <!-- [/l-contents] -->
    </div>
    <!-- [/l-wrapper] -->

    <UpdateAnnouncementModal ref="updateAnnouncementModal" />
    <ErrorModal ref="errorModal" />
  </body>
</template>

<script>
import SideBar from "./SideBar.vue";
import LastAccessDate from "../common/LastAccessDate.js";
import StorageExpiration from "@/common/StorageExpiration";
import UpdateAnnouncementModal from "@/components/UpdateAnnouncementModal.vue";
import HeaderArea from "./Header.vue";
import ErrorModal from "@/components/ErrorModal.vue";
import { COULD_NOT_GET_SIDEBAR_DATA } from "../const.js";
import Methods from "@/common/Methods";

export default {
  components: {
    SideBar,
    UpdateAnnouncementModal,
    HeaderArea,
    ErrorModal,
  },
  data() {
    return {
      materialTypes: [],
      searching: true,
      message: COULD_NOT_GET_SIDEBAR_DATA,
    };
  },
  mounted: function () {
    Methods.checkMoveToTop() ? this.$router.push({ name: "FST001_first" }) : "";
    StorageExpiration.check();
    this.$refs.updateAnnouncementModal.checkUpdate();
    LastAccessDate.update();
  },
  methods: {
    jsonData(data) {
      this.materialTypes = data.materialTypes;
      this.searching = false;
    },
    /*
     * 改行コードを<br>に変換する
     * @param {string} msg
     * @return {string} msg
     */

    htmlText(message) {
      if (message) {
        return message.replace(/\r?\n/g, "<br>");
      }
    },

    /**
     * エラーモーダルを開く
     */
    openErrorModal(statusCode) {
      this.$refs.errorModal.open(statusCode);
    },
  },
};
</script>
