<!--
    UpdateAnnouncementModal.vue
    更新告知モーダル
-->
<script>
import Cookie from "@/common/Cookie";

export default {
  name: "UpdateAnnouncementModal",
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    /**
     * 更新チェック
     */
    checkUpdate() {
      if (Cookie.exists(Cookie.Key.SERIAL_NUMBERS)) {
        return;
      }

      if (Cookie.exists(Cookie.Key.LAST_ACCESS_DATE)) {
        return;
      }

      this.isShow = true;
    },
    /**
     * モーダルクローズ
     */
    close() {
      this.isShow = false;

      // 初回シリアル入力に遷移
      this.$router.push({name: 'FST002_settings_serial-number_first'});
    },
  }
}
</script>

<template>
  <div
    class="l-common-contents__mask"
    :class="{ 'is-appear': isShow }"
    id="updateNoticedMaskElement"
    style="z-index: 10000"
  >
    <div class="c-modal update-notice">
      <div class="c-modal__head update-notice">
        <div class="c-modal__title update-notice">
          シリアルコード更新のお知らせ
        </div>
      </div>
      <div class="c-modal__body update-notice">
        <p class="c-txt--s">
          年度が変わりましたので、お手数ですが今年度のシリアルコードに更新してください。
        </p>
      </div>
      <div class="c-modal__foot update-notice">
        <button
          type="button"
          class="c-btn c-btn--l c-btn--bg-primary c-txt--l"
          @click="close()"
        >
          <span>閉じる</span>
        </button>
      </div>
    </div>
  </div>
</template>
