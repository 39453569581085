<!--
    PRV002_contents_preview_video.vue
    教材プレビュー_動画
 -->
<script>

import { API_URL } from "../const.js";
import Storage from "../common/Storage.js";
import axios from "axios";
import LastAccessDate from "../common/LastAccessDate.js";
import StorageExpiration from "@/common/StorageExpiration";
import UpdateAnnouncementModal from "@/components/UpdateAnnouncementModal.vue";
import ErrorModal from "@/components/ErrorModal.vue";
import Methods from "../common/Methods.js";

export default {
    name: "previewMovie",
    components: {
        UpdateAnnouncementModal,
        ErrorModal,
    },
    data() {
        return {
            // 右枠の表示対象データ
            displayData: [],
            // 動画関連のオブジェクト
            body: null,
            // 固有データ
            currentTimeText: null,
            currentVolume: 100,
            progressBarWidth: 0,
            // マウスクリック中かどうかを示すフラグ
            isMouseDown: false,
            startX: 0,
            pastX: 0,
            //
            isBodyFull: false,
            isPlaying: false,
            isMute: false,
            audioContext: null,
            videoSource: null,
            volumeNode: null,
            // ドラッグ&ドロップ中か判別用
            isDragging: false,
        };
    },
    mounted: function () {
        if(Methods.checkMoveToTop()){
            this.$router.push({ name: "FST001_first" })
            return
        }
        this.audioContext = new (window.AudioContext ||
            window.webkitAudioContext)();
        this.videoSource = this.audioContext.createMediaElementSource(this.$refs.video);
        this.volumeNode = this.audioContext.createGain();

        this.videoSource.connect(this.volumeNode);
        this.volumeNode.connect(this.audioContext.destination);

        // 初期音量の処理
        this.$refs.volumeBar.value / 100;
        this.volumeNode.gain.value = this.$refs.volumeBar.value / 100;
        if(this.isMute) {
            this.volumeNode.gain.value = 0;
        }
        this.updateSlider(this.$refs.volumeBar);

        StorageExpiration.check();
        this.$refs.updateAnnouncementModal.checkUpdate();
        LastAccessDate.update();
        this.callContentsPreviewApi();
    },
    methods: {
        // プレビュー対象の情報を取得する
        callContentsPreviewApi() {

            // 遷移先からのパラメータ取得処理
            const param = Storage.getVideoPreviewContents();
            if (param.length == 0) {
                // パラメータがない場合は500エラーにする
                this.$router.push({
                    name: 'ERR001_error',
                });
                return;
            }

            // データ取得
            const apiUrl = API_URL + "/contents/preview";
            axios.post(apiUrl, param)
                .then((res) => {
                    // パラメータから表示データをセット
                    if (res.data.length > 0) {
                        this.displayData = res.data[0];
                    }
                    this.initVideoSettings();
                })
                .catch((error) => {
                    console.log("error: " + error);
                    this.openErrorModal(error.response.status);
                });
        },
        initVideoSettings() {
            // 動画の設定値を取得
            this.progressBarWidth = this.$refs.progressBar.clientWidth;
            // 再生中の初期値設定
            this.isBodyFull = false;
            this.isPlaying = false;
            this.isMute = false;
            this.changeScreen();
        },
        // 全画面の切り替え
        changeScreen() {
            const body = document.querySelector('.preview-video');
            const fullScreenBtn = document.getElementById('fullScreenBtn');
            const target = document.getElementById('fullScreenContent');
            // 全画面/全画面を抜けるボタンを押下した時、フルスクリーンの制御を行う
            fullScreenBtn.addEventListener('click', () => {
                if (!document.webkitFullscreenElement) {
                    target.webkitRequestFullscreen();
                } else {
                  document.webkitExitFullscreen();
                }
            });
            // 全画面/全画面から抜けた際にクラスを付与
            document.addEventListener('webkitfullscreenchange', function () {
                if (document.webkitFullscreenElement) {
                    // ビデオが全画面になったときの処理
                    body.classList.add('is-full');
                } else {
                    // ビデオが全画面から抜けたときの処理
                    body.classList.remove('is-full');
                }
            });
        },
        // プログレスバー押下
        inputProgressBar() {
            this.$refs.video.currentTime = (this.$refs.video.duration * this.$refs.progressBar.value) / 100;
            this.updateSlider(this.$refs.progressBar);
        },
        // スライド時にプログレスバーの色を設定
        updateSlider (slider) {
            const progress = (slider.value / slider.max) * 100;
            slider.style.background = `linear-gradient(to right, #2b5fe5 ${progress}%, #ababab ${progress}%)`;
        },
        // 動画再生停止ボタン押下
        clickPlayButton() {
            if (this.isPlaying === false) {
                this.$refs.video.play();
                this.isPlaying = true;
            } else {
                this.$refs.video.pause();
                this.isPlaying = false;
            }
            this.audioContext.resume();
        },
        // 動画上再生ボタン押下
        clickPlayButtonLarge() {
            this.$refs.video.play();
            this.isPlaying = true;
        },
        // ミュートボタン押下時
        clickVolumeButton() {
            if (this.isMute === false) {
                this.isMute = true;
                this.volumeNode.gain.value = 0;
                this.currentVolume = this.$refs.volumeBar.value;
                this.$refs.volumeBar.value = 0;
            } else if (this.isMute === true && this.currentVolume / 100 !== 0) {
                this.isMute = false;
                this.$refs.volumeBar.value = this.currentVolume;
                this.volumeNode.gain.value = this.$refs.volumeBar.value / 100;
            }
            this.updateSlider(this.$refs.volumeBar);
        },
        // 音量バークリック時
        inputVolumeBar() {
            if(this.$refs.volumeBar.value / 100 === 0) {
                this.isMute = true;
                this.volumeNode.gain.value = 0;
            } else {
                this.isMute = false;
                this.volumeNode.gain.value = this.$refs.volumeBar.value / 100;
            }
            this.updateSlider(this.$refs.volumeBar);
        },
        // 再生時間はmountedで取得できないため、ページ読み込み完了後に実行させる
        loadeddataVideo() {
            if (Methods.checkMoveToTop()){
                return
            }
            this.timeupdateVideo();
        },
        // 動画再生中は再生位置を更新する
        timeupdateVideo() {
            // 動画の長さを取得
            const fullTime = this.$refs.video.duration;
            const totalMinutes = Math.floor(fullTime / 60);
            const totalSeconds = Math.floor(fullTime % 60);
            const totalTimeString = `${totalMinutes}:${
                totalSeconds < 10 ? '0' : ''
            }${totalSeconds}`;

            // 再生時間を取得
            let nowTime = this.$refs.video.currentTime;
            const setPos = (nowTime / fullTime) * 100;
            this.$refs.progressBar.value = setPos;
            const minutes = Math.floor(nowTime / 60);
            const seconds = Math.floor(nowTime % 60);
            const currentTimeString = `${minutes}:${
                seconds < 10 ? '0' : ''
            }${seconds}`;

            this.currentTimeText = `${currentTimeString}/${totalTimeString}`;
            this.updateSlider(this.$refs.progressBar);
        },
        // 動画終了時は再生ボタン切り替え
        endedVideo() {
            this.isPlaying = false;
        },
        // [SUB]現在再生中の動画の位置を割合で取得
        getVideoPositionToPer(pageX) {
            return (pageX - (this.$refs.progressBar.getBoundingClientRect().left + window.scrollX)) /
                this.$refs.progressBar.clientWidth;
        },
        // [SUB]現在音量の位置を割合で取得
        getVolumePositionToPer(pageX) {
            return (pageX - (this.$refs.volumeBar.getBoundingClientRect().left + window.scrollX)) /
                 this.$refs.volumeBar.clientWidth;
        },
        // ダウンロードボタン押下
        downloadContent() {
            // ファイル名の取得
            let fileName = this.displayData.contentFileUrl.split('/').slice(-1)[0];

            // ダウンロード処理（aリンクを内部生成する）
            const link = document.createElement('a');
            link.download = fileName;
            link.href = this.displayData.contentFileUrl;
            link.click();

            // ダウンロード後、このコンテンツIDを使用済みとして保存する
            Storage.addContentsIdToLocalstrage(this.displayData.contentId);
        },
        // コンテンツURLを分割してファイル名だけ取得する
        splitUrlToFileName(url) {
            if (url == null || url == undefined) {
                return '';
            }
            return url.split('/').pop();
        },

        /**
         * エラーモーダルを開く
         */
        openErrorModal(statusCode) {
            this.$refs.errorModal.open(statusCode);
        },
        // マウスダウン時
        handleDragStart() {
            this.isDragging = false;
        },
        // マウス移動時
        handleDragging() {
            this.isDragging = true;
        },
        // タッチイベント時
        handleClickForTouchEvent() {
            // ドラッグ&ドロップ中の場合はイベントを無視する
            if(this.isDragging) {
                return
            }
            this.downloadContent()
        },
        // マウスイベント時
        handleClickForMouseEvent(event) {
            // ドラッグ&ドロップ中または左クリック以外の場合はイベントを無視する
            if(
                this.isDragging 
                || event.button !== 0
            ) {
                return
            }
            this.downloadContent()
        },
    },
};
</script>


<template>
    <body class="preview-video" :class="{ 'is-full': isBodyFull }">
        <div class="l-wrapper">
            <div class="l-contents-preview" id="fullScreenContent">
                <header class="l-header-preview">
                    <div class="l-header-preview__container">
                        <div class="l-header-preview__title c-txt--xl">
                            {{ splitUrlToFileName(displayData.contentFileUrl)}}
                        </div>
                        <ul>
                            <li>
                                <button
                                    id="downloadBtnElement"
                                    @touchstart="handleDragStart()"
                                    @touchmove="handleDragging()"
                                    @touchend.prevent="handleClickForTouchEvent()"
                                    @mousedown="handleDragStart()"
                                    @mousemove="handleDragging()"
                                    @mouseup="handleClickForMouseEvent($event)"
                                >
                                    <img
                                        src="/assets/img/preview/icon_save.svg"
                                        alt=""
                                    />
                                </button>
                            </li>
                        </ul>
                    </div>
                </header>
                <main>
                    <div class="p-preview__area">
                        <div class="p-preview__video">
                            <video playsinline
                                ref="video"
                                :src="displayData.contentFileUrl"
                                @loadeddata="loadeddataVideo()"
                                @timeupdate="timeupdateVideo()"
                                @ended="endedVideo()"
                                @click="clickPlayButton()"
                            >
                            </video>
                        </div>
                        <button
                            class="p-preview-view__playback-l"
                            ref="playBtnLarge"
                            v-if="!isPlaying"
                            @click="clickPlayButtonLarge()"
                        >
                            <img src="/assets/img/preview/icon_start_btn_l.png">
                        </button>
                    </div>
                </main>
                <div class="p-preview-video__oper" id="controlBar">
                    <div class="p-preview-video__oper__container">
                        <div
                            class="p-preview__progress"
                        >
                        <input 
                                type="range" 
                                min="0" 
                                max="100" 
                                value="0" 
                                class="p-preview__progress-bar" 
                                ref="progressBar"
                                @input="inputProgressBar()"
                            >
                        </div>
                        <div class="p-preview-video__oper__inner">
                            <ul class="p-preview-video__oper__primary">
                                <li class="p-preview-video__oper__primary-item">
                                    <button
                                        class="p-preview-view__playback"
                                        :class="{ 'is-play': isPlaying, 'is-stop': !isPlaying }"
                                        ref="playBtn"
                                        @click="clickPlayButton()"
                                    >
                                    </button>
                                </li>
                                <li class="p-preview-video__oper__primary-item">
                                    <button
                                        class="p-preview-view__volume"
                                        :class="{ 'is-on': !isMute, 'is-off': isMute }"
                                        ref="volumeBtn"
                                        @click="clickVolumeButton()"
                                    >
                                    </button>
                                    <div class="p-preview__volume">
                                        <input
                                            type="range"
                                            min="0"
                                            max="100"
                                            id="volumeBar"
                                            class="p-preview__volume-bar"
                                            ref="volumeBar"
                                            value="100"
                                            @input="inputVolumeBar($event)"
                                        >
                                    </div>
                                </li>
                                <li class="p-preview-video__oper__primary-item">
                                    <div class="p-preview-view__time c-txt" id="displayTime">
                                        {{ currentTimeText }}
                                    </div>
                                </li>
                            </ul>
                            <button
                                class="p-preview-video__oper__secondary p-preview-view__screen"
                                ref="fullScreenBtn"
                                id="fullScreenBtn"
                                @click="changeScreen()"
                            >
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- [/l-wrapper] -->

        <UpdateAnnouncementModal ref="updateAnnouncementModal" />
        <ErrorModal ref="errorModal" />

    </body>
</template>

<script setup>
// DB.jsで読み込んだデータを取得
//import DB from '../DB.js'
</script>
