import { createRouter, createWebHistory } from "vue-router";

// コンポーネントをインポート
import FST001 from "../components/FST001_first.vue";
import FST002 from "../components/FST002_settings_serial-number_first.vue";
import SET001 from "../components/SET001_settings_textbook_first.vue";
import SET002 from "../components/SET002_settings_textbook.vue";
import UNI001 from "../components/UNI001_units.vue";
import UNI002 from "../components/UNI002_units_contents.vue";
import MTR001 from "../components/MTR001_materials.vue";
import MTR002 from "../components/MTR002_materials_contents.vue";
import SRC001 from "../components/SRC001_search-contents.vue";
import ERR001 from "../components/ERR001_error.vue";
import SRL001 from "../components/SRL001_settings_serial-number.vue";
import HLP001 from "../components/HLP001_help.vue";
import PRV001SEV from "../components/PRV001SEV_contents_preview_several-img.vue";
import PRV002 from "../components/PRV002_contents_preview_video.vue";

const routes = [
  {
    // 初回ページ
    path: "/",
    name: "FST001_first",
    component: FST001,
    meta: {
      title: "スマナビ 青葉出版 | プリント・動画・各種資料 ダウンロードサイト",
      description: "スマナビは、青葉出版のテスト／ドリル教材などをご採用いただいた先生が、授業で使えるプリント・動画・各種資料を印刷・ダウンロードできるサイトです。",

    },
  },
  {
    // 初回シリアル入力
    path: "/settings/serial-number/first",
    name: "FST002_settings_serial-number_first",
    component: FST002,
    meta: {
      title: "教材シリアルコード設定 | スマナビ",
    },
  },
  {
    // 学期制・教科書設定
    path: "/settings/textbook/first",
    name: "SET001_settings_textbook_first",
    component: SET001,
    meta: {
      title: "基本設定 | スマナビ",
    },
  },
  {
    // 設定
    path: "/settings/textbook",
    name: "SET002_settings_textbook",
    component: SET002,
    meta: {
      title: "基本設定 | スマナビ",
    },
  },
  {
    // 単元一覧
    path: "/units",
    name: "UNI001_units",
    component: UNI001,
    meta: {
      title: "単元から探す | スマナビ",
    },
  },
  {
    // 単元コンテンツ一覧
    path: "/units/:unitId/contents",
    name: "UNI002_units_contents",
    component: UNI002,
    meta: {
      title: "単元から探す | スマナビ",
    },
  },
  {
    // 教材一覧
    path: "/materials",
    name: "MTR001_materials",
    component: MTR001,
    meta: {
      title: "教材から探す | スマナビ",
    },
  },
  {
    // 教材一覧
    path: "/materials/:materialTypeId/contents",
    name: "MTR002_materials_contents",
    component: MTR002,
    meta: {
      title: "教材から探す | スマナビ",
    },
  },
  {
    // キーワード検索・結果一覧
    path: "/search-contents",
    name: "SRC001_search-contents",
    component: SRC001,
    meta: {
      title: "キーワードで絞る | スマナビ",
    },
  },
  {
    // 汎用エラー
    path: "/error",
    name: "ERR001_error",
    component: ERR001,
    meta: {
      title: "エラー | スマナビ",
    },
  },
  {
    // シリアルコード追加入力
    path: "/settings/serial-number",
    name: "SRL001_settings_serial-number",
    component: SRL001,
    meta: {
      title: "教材シリアルコード設定 | スマナビ",
    },
  },
  {
    // ヘルプページ
    path: "/help",
    name: "HLP001_help",
    component: HLP001,
    meta: {
      title: "よくある質問一覧 | スマナビ",
    },
  },
  {
    // 教材プレビュー_複数教材表示
    path: "/contents/preview",
    name: "PRV001SEV_contents_preview_several-img",
    component: PRV001SEV,
    meta: {
      title: "プレビュー | スマナビ",
    },
  },
  {
    // 教材プレビュー_動画
    path: "/contents/preview_mv",
    name: "PRV002_contents_preview_video",
    component: PRV002,
    meta: {
      title: "プレビュー | スマナビ",
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'ERR001_error',
    component: ERR001,
    meta: {
      title: "エラー | スマナビ",
    },
  },
];

const scrollBehavior = (to, from, savedPosition) => {
  // ヘルプページからトップページに遷移する際、またはsavedPositionがnullの場合はトップにスクロール
  if (
    !savedPosition
    || (to.path === '/' && from.path === '/help')
  ) {
    return { top: 0 }
  }
  // それ以外はsavedPositionの位置にスクロール
  return savedPosition
};

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior
});

router.beforeEach((to, from, next) => {
  // タイトル設定
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = "スマナビ";
  }

  // description設定
  const descriptionId = 'meta-description';
  const description = document.getElementById(descriptionId);
  if (description) {
    description.remove();
  }
  if (to.meta.description) {
    const description = document.createElement('meta');
    description.setAttribute('id', descriptionId);
    description.setAttribute('name', 'description');
    description.setAttribute('content', to.meta.description);
    document.head.appendChild(description);
  }

  // リファラー設定
  const referrer = document.referrer;
  Object.defineProperty(
    document,
    'referrer',
    {
      configurable: true,
      get: () => {
        return from.name
          ? window.location.origin + from.path
          : referrer
      },
    }
  )

  next();
});

export default router;
