import Cookie from "@/common/Cookie";

const LastAccessDate = {
    update: () => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        const currentDay = currentDate.getDate();
        const date = currentYear + "-" + currentMonth.toString().padStart(2, "0") + "-" + currentDay.toString().padStart(2, "0");
        Cookie.set(Cookie.Key.LAST_ACCESS_DATE, date);
    },

    isToday() {
        const lastAccessDate = Cookie.get(Cookie.Key.LAST_ACCESS_DATE);
        if (!lastAccessDate) {
            return false;
        }

        const today = new Date()
            .toLocaleDateString("ja-JP", {year: "numeric",month: "2-digit", day: "2-digit"})
            .replace(/\//g, '-')

        return lastAccessDate === today;
    },
};

export default LastAccessDate;
