import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
import DB from './DB.js'
import VueCookie from 'vue-cookies';

window.$router = router;


DB.load('name',function(){
    window.$app = createApp(App)
        .use(router)
        .use(VueCookie)
        .mount('#app')
})
