import { SUBJECT_COLORS, SUBJECT_KEYS } from "@/const";

const Color = {
        /*
        * 変更する教科によって、変更するスタイルの色を決定する
        * @param {Array} colors 色の種類を定義した配列
        * @param {String} name  bodyのカテゴリー名
        */

        getCategoryColor(colors, name) {
            let retColor = {
            main: "",
            sub: "",
            thin: "",
            main_hover: "",
            sub_hover: "",
            };

            const getColor = colors.filter((color) => {
            return color.name === name;
            });

            if (0 < getColor.length) {
            retColor.main = getColor[0].color.main;
            retColor.sub = getColor[0].color.sub;
            retColor.thin = getColor[0].color.thin;
            retColor.mainHover = getColor[0].color.main_hover;
            retColor.subHover = getColor[0].color.sub_hover;
            }

            return retColor;
        },

        /*
        *  選んだ教科によってbodyの色を変える
        */

        changeColorBySubject(selectedSubject) {
            const root = document.querySelector(":root");
            const body = document.querySelector("body");

            body.dataset.category = SUBJECT_KEYS[selectedSubject];
            const category = body.dataset.category;
            const getColor = this.getCategoryColor(SUBJECT_COLORS, category);

            const mainColor = getColor.main;
            const subColor = getColor.sub;
            const thinColor = getColor.thin;
            const mainHoverColor = getColor.mainHover;
            const subHoverColor = getColor.subHover;

            root.style.setProperty("--color-main", mainColor);
            root.style.setProperty("--color-sub", subColor);
            root.style.setProperty("--color-thin", thinColor);
            root.style.setProperty("--color-mainHover", mainHoverColor);
            root.style.setProperty("--color-subHover", subHoverColor);
        }
    };
export default Color;