<!--
    SRC001_search-contents.vue'
    キーワード検索・結果一覧
-->
<script>
import SideBar from "./SideBar.vue";
import HeaderArea from "./Header.vue";
import PageNav from "./PageNav.vue";
import ContentListFooter from "./ContentListFooter.vue";
import ErrorModal from "./ErrorModal.vue";
import axios from "axios";
import {
  PDF,
  API_URL,
  LS_KEY_USED_CONTENTS,
  COULD_NOT_GET_SIDEBAR_DATA,
  COULD_NOT_GET_SEARCH_DATA,
} from "../const.js";
import LastAccessDate from "../common/LastAccessDate.js";
import StorageExpiration from "@/common/StorageExpiration";
import UpdateAnnouncementModal from "@/components/UpdateAnnouncementModal.vue";
import Storage from "../common/Storage.js";
import Cookie from "@/common/Cookie";
import Methods from "@/common/Methods";
import ContentFile from "@/common/ContentFile";
import saveAs from "file-saver";

export default {
  components: {
    SideBar,
    HeaderArea,
    PageNav,
    ContentListFooter,
    ErrorModal,
    UpdateAnnouncementModal,
  },
  data() {
    return {
      isEmpty: true,
      json: {},
      contents: [],
      searchTerm: "",
      keywords: [],
      page: 1,
      totalPage: 1,
      excludeUsedContents: false,
      termType: Number(Cookie.getOrDefault(Cookie.Key.TERM_TYPE)),
      data: [],
      totalCount: 0,
      checkedContents: [],
      checkedContentsCount: 0,
      loaded: false,
      isAppearInfo: false,
      message: "",
      isCompatibleWithTouch: false,
      notSelectedFlg: false,
      SearchButtonFlg : false,
    };
  },
  mounted: function () {
    Methods.checkMoveToTop() ? this.$router.push({ name: "FST001_first" }) : "";
    StorageExpiration.check();
    this.$refs.updateAnnouncementModal.checkUpdate();
    LastAccessDate.update();
    this.isEmpty = Object.keys(this.json).length === 0;

    // 端末がタッチ（タップ）に対応しているか確認する
    const touch_event = window.ontouchstart;
    const touch_points = navigator.maxTouchPoints;
    if (touch_event !== undefined || 0 < touch_points) {
      // タッチ対応端末の処理が入る
      this.isCompatibleWithTouch = true;
    }

  },
  methods: {
    /*
     * 検索ボタンを押した時の動き
     */
    triggerSearch() {
      this.keywords = [];
      // 検索キーワードの取得
      if (this.searchTerm != "") {
        // this.search.term内のスペースを半角スペースに統一
        let searchWords = this.searchTerm.replace(/\u3000/g, " ");
        // スペース区切りされた文字列を配列に変換
        this.keywords = searchWords.split(" ");
      }
      // 使用済みコンテンツを非表示の値を取得する
      if (document.getElementById("useToggleBtn")) {
        this.excludeUsedContents =
          document.getElementById("useToggleBtn").checked;
      }
      this.page = 1;
      this.containSidebarRequest();
    },

    /*
     * サイドバーの値を検索条件に含み、検索を行う
     */

    containSidebarRequest() {
      // cookieから選択中の教科を取得する
      let selectedSubject = Cookie.getOrDefault(Cookie.Key.SELECTED_SUBJECT);

      // シリアルナンバーをローカルストレージから配列で取得する
      let serialNumbers = Cookie.getOrDefault(Cookie.Key.SERIAL_NUMBERS);
      // 使用済みコンテンツをローカルストレージから配列で取得する
      let usedContentIds = this.getUsedContentIdsToArray();

      let json = {
        serialNumbers: serialNumbers,
        subjectName: selectedSubject,
        gradeTextbookCompliances: [],
        terms: [],
        termType: this.termType,
        keywords: this.keywords,
        page: this.page,
        excludeUsedContents: this.excludeUsedContents,
        usedContentIds: usedContentIds,
      };

      // JSONデータ作成処理
      // ローカルストレージから教科書準拠を取得する
      let textbooks = Storage.get("textbooks");
      let textbooksData = JSON.parse(textbooks);

      // ローカルストレージの値の中から該当の教科書準拠の学年を取得する
      let targetCompliance = textbooksData.filter(
        (textbook) => textbook.subject == selectedSubject
      );
      // ローカルストレージから選択した学年を取得する
      let grades = Storage.get("selected_grades");
      let gradesData = JSON.parse(grades);

      // 選択中の教科と学年のセットを取得
      let targetGrades = gradesData.find(
        (grade) => grade.subject == selectedSubject
      );

      // チェックされている場合は、チェックされているチェックボックスのvalueをJSONに追加する
      // gradeTextbookCompliancesに値を追加する
      targetGrades.grades.forEach((grade) => {
        let targetComplianceObject = targetCompliance.find(
          (textbook) => textbook.grade == grade
        );
        if (targetComplianceObject) {
          json["gradeTextbookCompliances"].push({
            textbookComplianceName: targetComplianceObject.textbook,
            gradeName: targetComplianceObject.grade,
          });
        }
      });

      // ローカルストレージから選択した学期を取得する
      let terms = Storage.get("selected_terms");
      terms = JSON.parse(terms);

      // selected_terms全てのオブジェクトからfindで選択中の教科のオブジェクトを取得する
      let selectedTermObject = terms.find(
        (term) => term.subject == selectedSubject
      );

      // 選択中の教科のオブジェクトのvalueをJSONに追加する
      selectedTermObject.terms.forEach((semester) => {
        json["terms"].push(parseInt(semester));
      });

      // 未選択フラグの初期値をfalseにする
      this.notSelectedFlg = false;
      // 空の値を配列から削除する
      // 学年のオブジェクトを削除する
      if (json["gradeTextbookCompliances"].length <= 0) {
        delete json["gradeTextbookCompliances"];
        // 未選択フラグをtrueにする
        this.notSelectedFlg = true;
      }

      // 学期のオブジェクトを削除する
      if (json["terms"].length <= 0) {
        delete json["terms"];
        // 未選択フラグをtrueにする
        this.notSelectedFlg = true;
      }

      // keywordsのオブジェクトを削除する
      if (json["keywords"].length <= 0) {
        delete json["keywords"];
      }

      if (json["usedContentIds"] && json["usedContentIds"].length <= 0) {
        delete json["usedContentIds"];
      }     
      // サイドバーの値を検索条件に含み、検索を行う
      axios
        .post(API_URL + "/contents/search", json)
        .then((res) => {
          this.SearchButtonFlg = true;
          this.contents = res.data;
          this.totalCount = res.data.totalCount;
          if (this.notSelectedFlg) {
            this.message = COULD_NOT_GET_SIDEBAR_DATA;
          } else if (this.totalCount === 0) {
            this.message = COULD_NOT_GET_SEARCH_DATA;
          }
          this.totalPage = res.data.totalPage;
          this.isEmpty = Object.keys(json).length === 0;
          this.loaded = true;

          this.clearCheckedContents();
          this.contents.contents.map((c) => {
            c.isParentCheck = false;
            c.forStudent = false;
            c.forTeacher = false;
            c.isUsedFlg = this.isUsed(c.contentId);
          });
        })
        .catch((error) => {
          console.log("error: " + error);
          this.openErrorModal(error.response.status);
        });
    },

    sidebarSearch(json) {
      // 検索ボタン押下後でない場合は、検索を行わない
      if (!this.SearchButtonFlg) {
        return;
      }

      // 検索キーワードの取得
      if (this.searchTerm != "") {
        // this.search.term内のスペースを半角スペースに統一
        let searchWords = this.searchTerm.replace(/\u3000/g, " ");
        // スペース区切りされた文字列を配列に変換
        this.keywords = searchWords.split(" ");
        json.keywords = this.keywords;
      }

      // ページ数の取得
      this.page = 1;
      json.page = this.page;

      // 使用済みコンテンツを非表示の値を取得する
      if (document.getElementById("useToggleBtn")) {
        this.excludeUsedContents =
          document.getElementById("useToggleBtn").checked;
      }

      json.excludeUsedContents = this.excludeUsedContents;
      // 使用済みコンテンツをローカルストレージから配列で取得する
      let usedContentIds = this.getUsedContentIdsToArray();

      json.usedContentIds = usedContentIds;

      // 未選択フラグの初期値をfalseにする
      this.notSelectedFlg = false;

      if (json["gradeTextbookCompliances"] === undefined) {
        this.notSelectedFlg = true;
      }

      if (json["terms"] === undefined) {
        this.notSelectedFlg = true;
      }


      axios
        .post(API_URL + "/contents/search", json)
        .then((res) => {
          this.contents = res.data;
          this.totalCount = res.data.totalCount;
          if (this.notSelectedFlg) {
            this.message = COULD_NOT_GET_SIDEBAR_DATA;
          } else if (this.totalCount === 0) {
            this.message = COULD_NOT_GET_SEARCH_DATA;
          }
          this.totalPage = res.data.totalPage;
          this.isEmpty = Object.keys(json).length === 0;
          this.loaded = true;
          this.clearCheckedContents();
          this.contents.contents.map((c) => {
            c.isParentCheck = false;
            c.forStudent = false;
            c.forTeacher = false;
            c.isUsedFlg = this.isUsed(c.contentId);
          });
        })
        .catch((error) => {
          console.log("error: " + error);
          this.openErrorModal(error.response.status);
        });
      this.serialNumbers = null;
      this.keywords = [];
    },

    /*
     * 使用済みラベルの表示判定
     */
    isUsed(contentId) {
      let usedContentIds = this.getUsedContentIdsToArray();
      return usedContentIds && usedContentIds.includes(contentId);
    },

    /*
     * チェックボックスモーダルで選択解除を選択した場合、チェック状態をクリアする
     */

    clearCheckedContents() {
      this.checkedContents = [];
      this.checkedContentsCount = 0;
      this.contents.contents.filter((c) => {
        c.isParentCheck = false;
        c.forTeacher = false;
        c.forStudent = false;
      });
    },

    /*
     * チェックボックスのカウント処理
     */
    updateCheckedContents() {
      this.checkedContentsCount = 0;
      this.checkedContents = this.contents.contents.filter((c) => {
        if (c.isParentCheck) {
          this.checkedContentsCount++;
          if (c.forTeacher && c.forStudent) {
            this.checkedContentsCount++;
          }
          return true;
        }
      });
    },

    /*
     * コンテンツのチェックボックスを押下した時の動き
     * @param {object} content 対象コンテンツのオブジェクト
     */
    changeCheckContents(content) {
      if (
        (content.forStudent &&
          !content.forTeacher &&
          content.teacherContentUrl !== "") ||
        (!content.forStudent && content.forTeacher)
      ) {
        setTimeout(() => {
          content.isParentCheck = true;
          content.forStudent = content.studentContentUrl !== "";
          content.forTeacher = content.teacherContentUrl !== "";
          this.updateCheckedContents();
        });
      } else {
        setTimeout(() => {
          if (content.isParentCheck) {
            // チェック状態→未チェック状態
            content.isParentCheck = false;
            content.forStudent = false;
            content.forTeacher = false;
          } else {
            // 未チェック状態→チェック状態にした時
            content.isParentCheck = true;
            content.forStudent = content.studentContentUrl !== "";
            content.forTeacher = content.teacherContentUrl !== "";
          }
          this.updateCheckedContents();
        });
      }
    },

    /*
     * コンテンツ内の児童用・教師用チェックボックスを押下した時の動き
     * @param {object} content コンテンツのオブジェクト
     */

    changeCheckChildContents(content) {
      setTimeout(() => {
        if (content.forTeacher || content.forStudent) {
          content.isParentCheck = true;
        } else {
          content.isParentCheck = false;
        }
        this.updateCheckedContents();
      });
    },
    /*
     * プレビュー画面を開く
     * @param {object} content コンテンツのオブジェクト
     */
    openPreview(content) {
      if (!content.visible) {
        return;
      }

      if (content.contentType === 2) {
        Methods.openTab(content.contentUrl);
        return;
      }

      const teacherMaterialIncluded = content.teacherMaterialIncluded;

      const studentChecked = content.forStudent;
      const teacherChecked = content.forTeacher;
      const notChecked = !studentChecked && !teacherChecked;
      const bothChecked = studentChecked && teacherChecked;
      const shouldShowBoth = teacherMaterialIncluded && (notChecked || bothChecked);

      const storageData = [];
      if (content.studentContentUrl && (shouldShowBoth || studentChecked || !teacherMaterialIncluded)) {
        let _c = JSON.parse(JSON.stringify(content));
        _c.forStudent = true;
        storageData.push(_c);
      }
      if (content.teacherContentUrl && (shouldShowBoth || teacherChecked)) {
        let _c = JSON.parse(JSON.stringify(content));
        _c.forStudent = false;
        storageData.push(_c);
      }

      if (teacherMaterialIncluded && shouldShowBoth) {
        Storage.setPreviewListContents(storageData);
        Methods.openTab("/contents/preview");
        return;
      }

      const isOnlyStudent = !teacherMaterialIncluded || studentChecked;
      const fileUrl = isOnlyStudent ? content.studentContentUrl : content.teacherContentUrl;

      if (ContentFile.isPdf(fileUrl)) {
        this.addUsedContents(content.contentId);
        Methods.openTab(fileUrl);
        return;
      }

      if (ContentFile.isMovie(fileUrl)) {
        Storage.setVideoPreviewContents(storageData);
        Methods.openTab("/contents/preview_mv");
        return;
      }

      if (ContentFile.isExcel(fileUrl)) {
        Storage.setPreviewListContents(storageData);
        Methods.openTab("/contents/preview");
      }
    },
    /*
     * フォームクリアした時の動き
     */
    formClear() {
      this.searchTerm = "";
      this.keywords = [];
    },
    /*
     * ページネーションのページを移動の処理
     * @param {number} page
     */
    movePage(page) {
      this.page = page;
      this.containSidebarRequest();
    },
    /*
     * 使用済みコンテンツIDをローカルストレージに格納する
     * @param {number} contentIds
     */

    addUsedContents(contentIds) {
      if (!Array.isArray(contentIds)) contentIds = [contentIds];
      let usedContentIds = this.getUsedContentIdsToArray();
      usedContentIds = usedContentIds.concat(contentIds);
      usedContentIds = usedContentIds.filter(function (value, index, self) {
        return self.indexOf(value) === index;
      });
      Storage.set(LS_KEY_USED_CONTENTS, usedContentIds);
      // 使用済みフラグの更新
      this.contents.contents.map((c) => {
        if (contentIds.includes(c.contentId)) {
          c.isUsedFlg = true;
        }
      });
    },
    /*
     * アイコンをクリックした時にコピーする
     * @param {string} contentUrl 対象のhtmlのURL
     */

    CopyToClipboard(event, contentUrl) {
      // タッチデバイスの場合は、ヒントを表示する
      if(!window.matchMedia('(any-hover:hover)').matches) {
        // clickedElement はbutton要素、またはその内部のimgやspan要素
        let clickedElement = event.target;
        // button要素の内部にある `.p-common-contents__material__hint` クラスを持つ要素を探す
        let hint = clickedElement.querySelector('.p-common-contents__material__hint') || 
          clickedElement.closest('button').querySelector('.p-common-contents__material__hint');
        hint.style.display = 'block';
        setTimeout(() => {
          hint.style.display = 'none';
        },2000);
      }

      navigator.clipboard.writeText(contentUrl);
    },
    /*
     * ダウンロードアイコンと印刷アイコン押下時のPOSTデータ作成処理
     * チェックボックスの押下状況に応じて、POSTデータを作成する
     * @param {object} contents - アイコンクリック時の対象のコンテンツ
     * return {object} postData - POSTデータ
     */

    createPostData(contents) {
      let postData = [];
      // 親チェック状態によって、送るデータを変更する
      // [1]コンテンツの親チェックが入っていない
      if (!contents.isParentCheck) {
        // ①表示区分がfalseの場合は、児童用のみのコンテンツをtrueで送る
        if (!contents.teacherMaterialIncluded) {
          // 児童用のみ格納
          postData.push({
            contentId: contents.contentId,
            forStudent: true,
          });
        } else {
          // ②表示区分がtrueの場合は、児童用・教師用のコンテンツ両方を送る
          // 児童用格納
          postData.push({
            contentId: contents.contentId,
            forStudent: true,
          });
          // 教師用格納
          postData.push({
            contentId: contents.contentId,
            forStudent: false,
          });
        }
      } else {
        // [2]親チェックが入っている場合(マイナスチェックも含む)
        // ①表示区分がfalseの場合は、児童用のみのコンテンツをtrueで送る
        if (!contents.teacherMaterialIncluded) {
          // 児童用のみ格納
          postData.push({
            contentId: contents.contentId,
            forStudent: true,
          });
        } else {
          // ②表示区分がtrueの場合は、各チェック状況を確認する
          // 児童用にチェックが入っている場合
          // 児童用のデータを格納
          if (contents.forStudent) {
            postData.push({
              contentId: contents.contentId,
              forStudent: true,
            });
          }
          // 教師にチェックが入っている場合
          // 教師のデータを格納
          if (contents.forTeacher) {
            postData.push({
              contentId: contents.contentId,
              forStudent: false,
            });
          }
        }
      }
      return postData;
    },

    /*
     * ダウンロードアイコンボタンを押した時の処理
     * @param {object} contents - アイコンクリック時の対象のコンテンツ
     */

    downloadFile(contents) {
      // チェック状況に応じてPOSTデータを作成
      let postData = this.createPostData(contents);
      const params = { contents: postData };
      axios
        .post(API_URL + "/contents/download", params, { responseType: "blob" })
        .then((res) => {
          const mineType = res.headers["content-type"];
          const name = this.getDownloadFileName(res.headers["content-disposition"]);
          const blob = new Blob([res.data], { type: mineType });
          saveAs(blob, name);

          Storage.addContentsIdToLocalstrage(contents.contentId);
          contents.isUsedFlg = true;
        })
        .catch((error) => {
          console.log("error: " + error);
          this.openErrorModal(error.response.status);
        });
    },
    // content-dispositionからファイル名だけを取得
    getDownloadFileName(value) {
      if (value == undefined) {
        return "";
      }
      const splitted = value.split("filename=");
      if (splitted.length < 2) {
        return '';
      }
      return decodeURI(splitted[1].replace(/"/g, ''));
    },
    /*
     * 印刷アイコンボタンを押した時の処理
     * @param {object} contents - アイコンクリック時の対象コンテンツ
     */
    openPrintPreview(contents) {
      this.openMergedPdfContentsView(contents, () => {
        Storage.addContentsIdToLocalstrage(contents.contentId);
        contents.isUsedFlg = true;
      });
    },
    /**
     * 複数のPDFコンテンツを連結して開く
     */
    openMergedPdfContentsView(content, callback = () => {}) {
      // チェック状況に応じてPOSTデータを作成
      let postData = this.createPostData(content);
      const params = { contents: postData };
      axios
        .post(API_URL + "/pdf/merge", params)
        .then((res) => {
          callback();
          Methods.openTab(res.data.pdfFileUrl);
        })
        .catch((error) => {
          console.log("error: " + error);
          this.openErrorModal(error.response.status);
        });
    },

    /*
     * クリック時に使用済みコンテンツIDを削除する
     * @param {number} contentId
     * @param {event} event
     */
    removeUsedContentId(content) {
      // 使用済みコンテンツIDを取得する
      let usedContentIds = Storage.get(LS_KEY_USED_CONTENTS);
      // 使用済みIDが存在したら、配列に格納する
      if (usedContentIds) {
        usedContentIds = usedContentIds.split(",");
        let intUsedContentIds = usedContentIds.map((str) => parseInt(str, 10));
        // 配列内に存在するcontentIdを削除する(削除した配列を新たに作成)
        let removedUsedContentIds = intUsedContentIds.filter(
          (id) => id !== content.contentId
        );
        // 削除した配列をローカルストレージに格納する
        Storage.set(LS_KEY_USED_CONTENTS, removedUsedContentIds);
        // 使用済みフラグの更新
        content.isUsedFlg = false;
      }
    },

    /*
     * 使用済みコンテンツをローカルストレージから配列で取得する
     * @return {array} usedContentIds 使用済みコンテンツIDの配列
     */

    getUsedContentIdsToArray() {
      // 使用済みコンテンツを取得
      let usedContentIds = Storage.get(LS_KEY_USED_CONTENTS);
      if (usedContentIds) {
        usedContentIds = usedContentIds.split(",");
        usedContentIds = usedContentIds.map((str) => parseInt(str, 10));
      } else {
        usedContentIds = [];
      }
      return usedContentIds;
    },
    /*
     * information「(i)アイコン」をホバーした時にコンテンツを表示
     */

    showInformation() {
      this.isAppearInfo = true;
    },

    /*
     * information「(i)アイコン」をホバーした時にコンテンツを表示
     */
    hideInformation() {
      this.isAppearInfo = false;
    },

    /*
     * 改行コードを<br>に変換する
     * @param {string} msg
     * @return {string} msg
     */

    htmlText(message) {
      if (message) {
        return message.replace(/\r?\n/g, "<br>");
      }
    },

    /**
     * エラーモーダルを開く
     */
    openErrorModal(statusCode) {
      this.$refs.errorModal.open(statusCode);
    },
  },
};
</script>

<template>
  <body data-category="national-language">
    <div class="l-wrapper">
      <!-- 一般的 -->
      <HeaderArea
        :logoRedFlag="true"
        :logoSideFlag="true"
        :menuFlag="true"
        @openErrorModal="openErrorModal"
      />
      <div class="l-contents teaching-unit teaching-unit--content">
        <SideBar
          v-on:getData="sidebarSearch"
          @openErrorModal="openErrorModal"
        />
        <main class="l-common-contents" v-if="totalCount === 0">
          <div class="l-common-contents__body" data-background="gray">
            <form class="p-keyword-search__form">
              <div class="p-keyword-search__inner">
                <input
                  type="text"
                  class="p-keyword-search__input c-txt--l"
                  placeholder="キーワードを入力してください"
                  v-model="searchTerm"
                />
                <button
                  type="button"
                  class="c-btn__clear js-formClearBtn"
                  @click="formClear"
                  v-if="searchTerm !== ''"
                >
                  <img src="/assets/img/common/icon_clear.svg" />
                </button>
              </div>
              <button
                type="submit"
                class="c-btn__search"
                v-on:click.prevent="triggerSearch()"
              >
                <img src="/assets/img/common/icon_search-white.svg" />
              </button>
            </form>
            <div></div>
            <div class="p-teaching-unit-list__body" v-if="!isEmpty">
              <div class="p-teaching-unit-content__detail">
                <div class="p-teaching-unit-content__detail__inner">
                  <p class="p-teaching-unit-content__number c-txt--s">
                    検索結果：<span>{{ contents.totalCount }}</span> 件
                  </p>
                  <label class="p-teaching-unit-content__detail__toggle-switch">
                    <input
                      type="checkbox"
                      id="useToggleBtn"
                      v-on:click="triggerSearch()"
                      v-model="excludeUsedContents"
                    />
                    <span
                      class="p-teaching-unit-content__detail__slider"
                    ></span>
                  </label>
                  <p class="p-teaching-unit-content__detail__txt c-txt">
                    使用済みコンテンツを非表示
                  </p>
                  <div
                    class="c-block-info"
                    :class="{ 'is-appear': isAppearInfo }"
                  >
                    <p class="c-block-info__txt c-txt--xs">
                      印刷またはダウンロードを行っているコンテンツを非表示にすることができます。
                      すでに印刷やダウンロードを行った教材は、[使用済み]のラベルを表示します。
                    </p>
                  </div>
                  <button
                    class="p-teaching-unit-content__detail__info"
                    v-on:mouseover="showInformation()"
                    v-on:mouseleave="hideInformation()"
                  >
                    <img src="/assets/img/common/icon_info-gray.svg" alt="" />
                  </button>
                </div>
              </div>
            </div>
            <div
              class="l-common-contents__body__error"
              v-if="!isEmpty && loaded"
            >
              <p
                class="p-common-contents__body__error"
                v-html="htmlText(message)"
              ></p>
            </div>
          </div>
        </main>

        <main class="l-common-contents" v-else>
          <div class="l-common-contents__body" data-background="gray">
            <form class="p-keyword-search__form">
              <div class="p-keyword-search__inner">
                <input
                  type="text"
                  class="p-keyword-search__input c-txt--l"
                  placeholder="キーワードを入力してください"
                  v-model="searchTerm"
                />
                <button
                  type="button"
                  class="c-btn__clear js-formClearBtn"
                  @click="formClear"
                  v-if="searchTerm !== ''"
                >
                  <img src="/assets/img/common/icon_clear.svg" />
                </button>
              </div>
              <button
                type="submit"
                class="c-btn__search"
                v-on:click.prevent="triggerSearch()"
              >
                <img src="/assets/img/common/icon_search-white.svg" />
              </button>
            </form>
            <div></div>
            <div class="p-teaching-unit-list__body" v-if="!isEmpty">
              <div class="p-teaching-unit-content__detail">
                <div class="p-teaching-unit-content__detail__inner">
                  <p class="p-teaching-unit-content__number c-txt--s">
                    検索結果：<span>{{ contents.totalCount }}</span> 件
                  </p>
                  <label class="p-teaching-unit-content__detail__toggle-switch">
                    <input
                      type="checkbox"
                      id="useToggleBtn"
                      v-on:click="triggerSearch()"
                    />
                    <span
                      class="p-teaching-unit-content__detail__slider"
                    ></span>
                  </label>
                  <p class="p-teaching-unit-content__detail__txt c-txt">
                    使用済みコンテンツを非表示
                  </p>
                  <button
                    class="p-teaching-unit-content__detail__info"
                    v-on:mouseover="showInformation()"
                    v-on:mouseleave="hideInformation()"
                  >
                    <img src="/assets/img/common/icon_info-gray.svg" alt="" />
                    <div
                      class="c-block-info"
                      :class="{ 'is-appear': isAppearInfo }"
                    >
                      <p class="c-block-info__txt c-txt--xs">
                        印刷またはダウンロードを行っているコンテンツを非表示にすることができます。
                        すでに印刷やダウンロードを行った教材は、[使用済み]のラベルを表示します。
                      </p>
                    </div>
                  </button>
                </div>
              </div>
              <!-- ページネーション -->
              <PageNav
                :currentPage="page"
                :totalPage="totalPage"
                @movePage="movePage"
              ></PageNav>
              <!-- ページネーション -->
              <div
                class="p-keyword-search__container js-container"
                data-background="gray"
                v-if="json.length !== 'undefined'"
              >
                <div
                  class="p-common-contents__content-outer search js-unitBody"
                >
                  <a
                    class="p-common-contents__container-material"
                    v-for="content in contents.contents"
                    :key="content.contentId"
                    :class="{ lock: !content.visible }"
                    @click="openPreview(content)"
                  >
                    <div class="p-common-contents__material-img__wrapper">
                      <div class="p-common-contents__material-img">
                        <label
                            class="c-checkbox"
                            @click.stop="changeCheckContents(content)"
                            v-if="content.contentType === 1 && content.visible"
                        >
                          <input
                              type="checkbox"
                              class="c-checkbox__input js-single"
                              :class="{
                          'minus-check':
                            (content.forStudent &&
                              !content.forTeacher &&
                              content.teacherContentUrl !== '') ||
                            (!content.forStudent && content.forTeacher),
                        }"
                              v-model="content.isParentCheck"
                          />
                          <span class="c-checkbox__txt"> </span>
                        </label>

                        <div class="c-block-used" v-if="content.isUsedFlg" style="z-index: 80;">
                          <div class="c-block-used__txt c-txt--xxs">使用済み</div>
                          <button
                              type="button"
                              class="c-block-used__img"
                              v-on:click.stop="removeUsedContentId(content)"
                          >
                            <img src="/assets/img/common/icon_x.svg" alt="" />
                          </button>
                        </div>
                        <div class="p-common-contents__material-img-innerframe">
                          <img
                              v-if="content.studentThumbnailUrl"
                              v-bind:src="content.studentThumbnailUrl"
                              onerror="this.src='/assets/img/common/dummy/noimage.png';"
                              alt=""
                          />
                          <img
                              v-else-if="content.teacherThumbnailUrl"
                              v-bind:src="content.teacherThumbnailUrl"
                              onerror="this.src='/assets/img/common/dummy/noimage.png';"
                              alt=""
                          />
                          <img
                              v-else
                              src="/assets/img/common/dummy/noimage.png"
                              alt=""
                          />
                        </div>
                      </div>
                      <div
                          class="p-common-contents__material__icon-double"
                          :style="isCompatibleWithTouch ? 'display: flex' : ''"
                          v-if="
                        content.contentType === 1 &&
                        content.visible &&
                        (content.studentContentUrl !== null ||
                          content.teacherContentUrl !== null) &&
                        (content.studentContentUrl.split('.').pop() === PDF ||
                          content.teacherContentUrl.split('.').pop() === PDF)
                      "
                      >
                        <button
                            class="p-common-contents__material__icon"
                            v-on:click.stop="openPrintPreview(content)"
                            ontouchstart=""
                        >
                          <img
                              src="/assets/img/common/icon_tab_blank-white.svg"
                              alt=""
                          />
                        </button>
                        <button
                            class="p-common-contents__material__icon"
                            v-on:click.stop="downloadFile(content)"
                            ontouchstart=""
                        >
                          <img
                              src="/assets/img/common/icon_save-white.svg"
                              alt=""
                          />
                        </button>
                      </div>

                      <button
                          class="p-common-contents__material__icon p-common-contents__material__icon-position"
                          :style="isCompatibleWithTouch ? 'display: block' : ''"
                          v-else-if="content.contentType === 1 && content.visible"
                          ontouchstart=""
                          v-on:click.stop="downloadFile(content)"
                      >
                        <img
                            src="/assets/img/common/icon_save-white.svg"
                            alt=""
                        />
                      </button>

                      <button
                          class="p-common-contents__material__icon p-common-contents__material__icon-position"
                          :style="isCompatibleWithTouch ? 'display: block' : ''"
                          v-else-if="content.contentType === 2 && content.visible"
                          ontouchstart=""
                          v-on:click.stop="CopyToClipboard($event, content.contentUrl)"
                      >
                        <img
                            src="/assets/img/common/icon_link-white.svg"
                            alt=""
                        />
                        <span class="p-common-contents__material__hint">
                          リンクをコピー
                      </span>
                      </button>


                      <div
                          v-if="!content.visible"
                          class="p-common-contents__material__lock-mask"
                          style="border-radius: 16px 16px 0 0;"
                      >
                        <div class="p-common-contents__material__lock">
                          <img
                              src="/assets/img/common/icon_lock-white.svg"
                              alt=""
                          />
                        </div>
                        <p class="c-txt--xxs">
                          使用にはシリアルコードの入力が必要です
                        </p>
                      </div>

                    </div>

                    <div class="p-common-contents__material-content">
                      <div class="p-common-contents__material__outer-title">
                        <p class="p-common-contents__material__title c-txt--xs">
                          {{ content.gradeName }} {{ content.unitName }}
                        </p>
                      </div>
                      <p class="p-common-contents__material__txt c-txt">
                        {{ content.materialName }}
                      </p>
                      <p class="p-common-contents__material__txt c-txt">
                        {{ content.description }}
                      </p>
                      <div
                        class="p-common-contents__material__btns"
                        v-if="
                          content.visible &&
                          content.contentType === 1 &&
                          content.teacherMaterialIncluded &&
                          content.studentContentUrl !== '' &&
                          content.teacherContentUrl !== ''
                        "
                      >
                        <button class="p-common-contents__material__btn">
                          <label
                            class="c-checkbox"
                            @click.stop="changeCheckChildContents(content)"
                          >
                            <input
                              type="checkbox"
                              name="児童用"
                              class="c-checkbox__input js-side"
                              v-model="content.forStudent"
                            />
                            <span class="c-checkbox__txt">児童用</span>
                          </label>
                        </button>
                        <button class="p-common-contents__material__btn">
                          <label
                            class="c-checkbox"
                            @click.stop="changeCheckChildContents(content)"
                          >
                            <input
                              type="checkbox"
                              name="教師用"
                              class="c-checkbox__input js-side"
                              v-model="content.forTeacher"
                            />
                            <span class="c-checkbox__txt">教師用</span>
                          </label>
                        </button>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <!-- ページネーション -->
              <PageNav
                :currentPage="page"
                :totalPage="totalPage"
                @movePage="movePage"
              ></PageNav>
              <!-- ページネーション -->
            </div>
          </div>

          <ContentListFooter
            :checkedContents="checkedContents"
            :checkedContentsCount="checkedContentsCount"
            @clearCheckedContents="clearCheckedContents"
            @addUsedContents="addUsedContents"
            @openErrorModal="openErrorModal"
          />
        </main>
      </div>
      <!-- [/l-contents] -->
    </div>
    <ErrorModal ref="errorModal" />
    <!-- [/l-wrapper] -->

    <UpdateAnnouncementModal ref="updateAnnouncementModal" />
  </body>
</template>

<script setup>
// DB.jsで読み込んだデータを取得
//import DB from '../DB.js'
</script>
