const Cookie = {
    Key: {
        SERIAL_NUMBERS: "serial_numbers",
        TERM_TYPE: "term_type",
        SELECTED_SUBJECT: "selected_subject",
        LAST_ACCESS_DATE: "last_access_date",
    },
    expiresDate: new Date(new Date().getFullYear() + 1, 3, 1, 0, 0, 0), // 次年度/04/01 00:00:00

    get(key) {
        return window.$app.$cookies.get(key);
    },

    set(key, value) {
        window.$app.$cookies.set(key, value, this.expiresDate);
    },

    exists(key) {
        return window.$app.$cookies.isKey(key);
    },

    getOrDefault(key, defaultValue = null) {
        switch (key) {
            case this.Key.SERIAL_NUMBERS:
                return this.get(key) ?? defaultValue ?? [];
            case this.Key.TERM_TYPE:
                return this.get(key) ?? defaultValue ?? "3";
            case this.Key.SELECTED_SUBJECT:
                return this.get(key) ?? defaultValue ?? "国語";
        }
        return this.get(key) ?? defaultValue;
    },
};

export default Cookie;
