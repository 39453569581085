<template>
         <div class="l-common-contents__foot-fixed" :class="{'is-none' : checkedContentsCount === 0}">
             <div class="p-common-contents-foot__inners">
               <p class="p-common-contents-foot__select c-txt--l">
                 <span class="p-common-contents-foot__select-text">{{ checkedContentsCount }}</span
                 >件を選択中
               </p>
               <button class="p-common-contents-foot__select-release c-txt--xs" @click="clearCheckedContents">
                 選択解除
               </button>
               <div class="p-common-contents-foot__container-btns">
                 <a class="p-common-contents-foot__btn c-txt--xs js-tabOpenBtn" @click="open">
                   <div class="p-common-contents-foot__btn__img">
                     <img
                       src="/assets/img/common/icon_prev-white.svg"
                       alt=""
                     />
                   </div>
                   プレビュー</a
                 >
                 <button class="p-common-contents-foot__btn c-txt--xs" 
                 :class="{'disabled': printButtonDisabled}"
                 @click="openPrintPreview">
                   <div class="p-common-contents-foot__btn__img">
                     <img src="/assets/img/common/icon_tab_blank-white.svg" alt="" />
                   </div>
                   開く
                 </button>
                 <button class="p-common-contents-foot__btn c-txt--xs" @click="downloadFile">
                   <div class="p-common-contents-foot__btn__img">
                     <img src="/assets/img/common/icon_save-white.svg" alt="" />
                   </div>
                   ダウンロード
                 </button>
               </div>
             </div>
           </div>
</template>
<script>
    import axios from "axios";
    import { API_URL } from "../const.js";
    import Storage from "../common/Storage.js";
    import Methods from "@/common/Methods";
    import ContentFile from "@/common/ContentFile";
    import saveAs from "file-saver";

    export default {
        name: "ContentListFooter", 
        props:{
            checkedContents: {
                type: Array,
                default: () => ([])
            },
            checkedContentsCount: {
                default: 0
            },
        },
        emits: [
            "openErrorModal",
        ],
        computed: {
            printButtonDisabled() {
                return this.checkedContents.filter(c => {
                    if (c.forTeacher || !c.forStudent) {
                        let file = c.teacherContentUrl;
                        if (file) {
                            let file_type = file.split('.').pop();
                            if (file_type !== "pdf") return true;
                        } else {
                            return true;
                        }
                    }
                    if (c.forStudent) {
                        let file = c.studentContentUrl;
                        if (file) {
                            let file_type = file.split('.').pop();
                            if (file_type !== "pdf") return true;
                        } else {
                            return true;
                        }
                    }
                    return false;
                }).length > 0;
                
            }
        },
        methods: {
            clearCheckedContents() {
                this.$emit('clearCheckedContents');
            },
            addUsedContents(contentIds) {
                this.$emit('addUsedContents', contentIds);
            },
            open() {
                let storageData = [];
                let contentIds = [];
                let _checkedContents = JSON.parse(JSON.stringify(this.checkedContents));
                _checkedContents.map(
                    c => {
                        if (c.forStudent) {
                            storageData.push(c);
                        }
                        if (!c.forStudent || c.forTeacher) {
                            let _c = JSON.parse(JSON.stringify(c));
                            _c.forStudent = false;
                            storageData.push(_c);
                        }
                        contentIds.push(c.contentId);
                    }
                )

                // 複数のコンテンツを開く場合は、プレビュー画面を開く
                if (storageData.length > 1) {
                    Storage.setPreviewListContents(storageData);
                    Methods.openTab("/contents/preview");
                    return;
                }

                const content = storageData[0];
                const isStudent = content.forStudent;
                const fileUrl = isStudent ? content.studentContentUrl : content.teacherContentUrl;

                if (ContentFile.isPdf(fileUrl)) {
                    this.addUsedContents(contentIds);
                    Methods.openTab(fileUrl);
                    return;
                }

                if (ContentFile.isMovie(fileUrl)) {
                    Storage.setVideoPreviewContents(storageData);
                    Methods.openTab("/contents/preview_mv");
                    return;
                }

                if (ContentFile.isExcel(fileUrl)) {
                    Storage.setPreviewListContents(storageData);
                    Methods.openTab("/contents/preview");
                }
            },
            openPrintPreview() {
                let contentIds = [];
                let postData = [];
                this.checkedContents.map(
                    c => {
                        if (c.forStudent) {
                            postData.push({
                                "contentId": c.contentId,
                                "forStudent": true
                            });
                        }
                        if (!c.forStudent || c.forTeacher) {
                            postData.push({
                                "contentId": c.contentId,
                                "forStudent": false
                            });
                        }
                        contentIds.push(c.contentId);
                    }
                )
                const params = {"contents": postData};
                axios
                    .post(API_URL + "/pdf/merge", params)
                    .then((res) => {
                        Methods.openTab(res.data.pdfFileUrl);
                        this.addUsedContents(contentIds);
                    })
                    .catch((error) => {
                        console.log("error: " + error);
                        this.$emit('openErrorModal', error.response.status);
                    });
            },
            downloadFile() {
                let contentIds = [];
                let postData = [];
                this.checkedContents.map(
                    c => {
                        if (!c.forStudent || c.forTeacher) {
                            postData.push({
                                "contentId": c.contentId,
                                "forStudent": false
                            });
                        }
                        if (c.forStudent) {
                            postData.push({
                                "contentId": c.contentId,
                                "forStudent": true
                            });
                        }
                        contentIds.push(c.contentId);
                    }
                )
                const params = {"contents": postData};
                axios
                    .post(API_URL + "/contents/download", params, { responseType: "blob" })
                    .then((res) => {
                        const mineType = res.headers["content-type"];
                        const name = this.getDownloadFileName(res.headers["content-disposition"]);
                        const blob = new Blob([res.data], { type: mineType });
                        saveAs(blob, name);
                        this.addUsedContents(contentIds);
                    })
                    .catch((error) => {
                        console.log("error: " + error);
                        this.$emit('openErrorModal', error.response.status);
                    });
            },
            // content-dispositionからファイル名だけを取得
            getDownloadFileName(value) {
                if (value == undefined) {
                    return "";
                }
                const splitted = value.split("filename=");
                if (splitted.length < 2) {
                    return '';
                }
                return decodeURI(splitted[1].replace(/"/g, ''));
            },
        }
    }
</script>