import LastAccessDate from "@/common/LastAccessDate";

const Methods = {
    init: function () {},

    load: function (param, callback) {
        callback();
    },

    /**
     * 別タブでURLを開く
     */
    openTab(url) {
        // iOS/Safariの場合、window.openで開けない場合があるため、location.hrefで開く
        if (!window.open(url)) {
            location.href = url;
        }
    },

    /*
    * パラメータのファイル文字列(URL可能)から拡張子を取得する
    *
    * Param   : ファイル文字列・URL
    * Returns : [String] ファイル拡張子
    */
    getFileExtension(value) {
        if (value === null || value === undefined) {
            return false;
        } else if (value.length === 0) {
            return false;
        } else if (value.indexOf('.') === -1) {
            return false;
        }
        return value.split('.').slice(-1)[0].toLowerCase();
    },

    /*
    * リファラチェックと同日アクセスチェック
    * 外部アクセスかつ別日の場合は、スタートページへリダイレクト
    */ 
   checkMoveToTop() {
        const referrer = document.referrer;
        const currentHost = window.location.origin;

        // リファラが存在し、同一ドメインの場合は処理をスキップ
        if (referrer && new URL(referrer).origin.startsWith(currentHost)) {
            return false;
        }

        if (LastAccessDate.isToday()) {
            return false;
        }

        return true;
   }

};

export default Methods;
