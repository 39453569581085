<template>
    <div class="l-common-contents__mask"
        :class="{'is-none': !showModal}"
        id="allSelectedMaskElement">
      <div class="c-modal all-selected">
        <div class="c-modal__head all-selected">
          <button type="button" class="c-modal__back all-selected" @click="closeModal()">
            <img src="/assets/img/common/icon_x-black.svg" alt="" />
          </button>
          <div class="c-modal__title all-selected">全て選択する教材を選んでください</div>
        </div>
        <div class="c-modal__foot all-selected">
          <button
            type="button"
            class="c-btn c-btn--m c-btn--bg-quinary c-txt c-btn__txt"
            id="allCheckedBtn"
            @click="selectAllCheck(['student', 'teacher'])"
          >
            <span>児童用・教師用</span>
          </button>
          <button
            type="button"
            class="c-btn c-btn--m c-btn--bg-quinary c-txt c-btn__txt"
            id="childCheckedBtn"
            @click="selectAllCheck(['student'])"
          >
            <span>児童用のみ</span>
          </button>
          <button
            type="button"
            class="c-btn c-btn--m c-btn--bg-quinary c-txt c-btn__txt"
            id="teacherCheckedBtn"
            @click="selectAllCheck(['teacher'])"
          >
            <span>教師用のみ</span>
          </button>
        </div>
      </div>
    </div>
</template>
<script>

    export default {
        name: "AllCheckModal", 
        data() {
            return {
                showModal: false
            };
        },
        props:{
            targetAllCheckMaterialTypes: {
            },
        }, 
        methods: {
            selectAllCheck(types) {
                if (this.targetAllCheckMaterialTypes) {
                    this.$emit('allCheckModalClick', types, this.targetAllCheckMaterialTypes);
                    this.closeModal();
                }
            },
            closeModal() {
                this.showModal = false;
            },
        }
    }
 </script>