<template>
<!-- ページネーション -->
<div class="l-pager-nav">
    <ul class="l-pager-nav__list">
        <li class="l-pager-nav__list-item" v-if="currentPage > 1">
            <a
                href="#"
                class="l-pager-nav__list-link l-pager-nav__list-link--prev"
                @click="movePage(currentPage - 1)"
            ></a>
        </li>
        <li class="l-pager-nav__list-item"
         :class="{'dot': pageInfo.isDots}"
         v-for="pageInfo in pageList"
         :key="pageInfo.num">
            <a v-if="!pageInfo.isDots"
                href="#"
                class="l-pager-nav__list-link"
                :class="{'is-current': pageInfo.isCurrent}"
                @click="movePage( pageInfo.num)"
                >{{ pageInfo.num }}</a
            >
            <a v-else
                class="l-pager-nav__list-item__dot is-disabled"
                >･･･</a
            >
        </li>
        <li class="l-pager-nav__list-item" v-if="currentPage < totalPage">
            <a
                href="#"
                class="l-pager-nav__list-link l-pager-nav__list-link--next"
                @click="movePage(currentPage + 1)"
            ></a>
        </li>
    </ul>
</div>
<!-- ページネーション -->
</template>
<script>
    export default {
        name: "PageNav",
        props:{
            'currentPage': {
            },
            'totalPage': {
            },
        },

        computed:{
            pageList() {
                let _pageList = [];
                if (this.totalPage <= 5) {
                    for(let i = 1; i <= this.totalPage; i++) {
                        _pageList.push({
                            num: i,
                            isCurrent: i == this.currentPage,
                            isDots: false
                        });
                    }
                    return _pageList;
                } else {
                    if (this.currentPage < 4) {
                        for(let i = 1; i <= 4; i++) {
                            _pageList.push({
                                num: i,
                                isCurrent: i == this.currentPage,
                                isDots: false
                            });
                        }
                        _pageList.push({
                            isDots: true,
                            num: -1
                        });
                        _pageList.push({
                            num: this.totalPage,
                            isCurrent: false,
                            isDots: false
                        });
                    } else if (4 <= this.currentPage && this.currentPage <= (this.totalPage - 3)) {
                        _pageList.push({
                            num: 1,
                            isCurrent: false,
                            isDots: false
                        });
                        _pageList.push({
                            isDots: true,
                            num: -1
                        });
                        for(let i = this.currentPage - 1; i <= this.currentPage + 1; i++) {
                            _pageList.push({
                                num: i,
                                isCurrent: i == this.currentPage,
                                isDots: false
                            });
                        }
                        _pageList.push({
                            isDots: true,
                            num: -2
                        });
                        _pageList.push({
                            num: this.totalPage,
                            isCurrent: false,
                            isDots: false
                        });
                    } else {
                        _pageList.push({
                            num: 1,
                            isCurrent: false,
                            isDots: false
                        });
                        _pageList.push({
                            isDots: true,
                            num: -1
                        });
                        for(let i = this.totalPage - 3; i <= this.totalPage; i++) {
                            _pageList.push({
                                num: i,
                                isCurrent: i == this.currentPage,
                                isDots: false
                            });
                        }
                    }

                }
                return _pageList;
            }
        },

        methods: {
            movePage(page){
                this.$emit('movePage', page);
            }
        }
    }
 </script>