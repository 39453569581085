const ContentFile = {
    Extension: {
        PDF: ["pdf"],
        MOVIE: ["mp4"],
        EXCEL: ["xlsx", "xls", "xlsm"],
    },

    isPdf(filepath) {
        const extension = this.getExtension(filepath);
        return this.Extension.PDF.includes(extension);
    },

    isMovie(filepath) {
        const extension = this.getExtension(filepath);
        return this.Extension.MOVIE.includes(extension);
    },

    isExcel(filepath) {
        const extension = this.getExtension(filepath);
        return this.Extension.EXCEL.includes(extension);
    },

    getExtension(filepath) {
        if (!filepath) {
            return false;
        }

        if (!(typeof filepath === "string" || filepath instanceof String)) {
            return false;
        }

        return filepath.split(".").pop().toLowerCase();
    }
};

export default ContentFile;
