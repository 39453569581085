<!--
    MTR002_materials_contents.vue
    教材一覧
 -->

<template>
  <body data-category="national-language">
    <div class="l-wrapper">
      <HeaderArea
        :logoRedFlag="true"
        :logoSideFlag="true"
        :menuFlag="true"
        @openErrorModal="openErrorModal"
      />
      <div class="l-contents teaching-unit teaching-unit--content">
        <SideBar
          v-on:getData="jsonData"
          @openErrorModal="openErrorModal"
        />
        <main
          class="l-common-contents"
          v-if="this.contentsCount === 0 && !this.searching"
        >
          <div class="l-common-contents__body" data-background="gray">
            <div class="p-teaching-unit-list__head back">
              <router-link
                :to="{ name: 'MTR001_materials' }"
                class="p-teaching-unit-content__back c-txt"
                >教材一覧に戻る</router-link
              >
            </div>
            <div class="p-teaching-unit-content__detail">
              <div class="p-teaching-unit-content__detail__inner">
                <label class="p-teaching-unit-content__detail__toggle-switch">
                  <input
                    type="checkbox"
                    id="useToggleBtn"
                    v-on:click="SwitchExcludeUsedContents()"
                    v-model="excludeUsedContents"
                  />
                  <span class="p-teaching-unit-content__detail__slider"></span>
                </label>
                <p class="p-teaching-unit-content__detail__txt c-txt">
                  使用済みコンテンツを非表示
                </p>
                <button
                  class="p-teaching-unit-content__detail__info"
                  v-on:mouseover="showInformation()"
                  v-on:mouseleave="hideInformation()"
                >
                  <img src="/assets/img/common/icon_info-gray.svg" alt="" />
                  <div
                    class="c-block-info"
                    :class="{ 'is-appear': isAppearInfo }"
                  >
                    <p class="c-block-info__txt c-txt--xs">
                      印刷またはダウンロードを行っているコンテンツを非表示にすることができます。
                      すでに印刷やダウンロードを行った教材は、[使用済み]のラベルを表示します。
                    </p>
                  </div>
                </button>
              </div>
            </div>
            <div class="l-common-contents__body__error">
              <p
                class="p-common-contents__body__error"
                v-html="htmlText(message)"
              ></p>
            </div>
          </div>
        </main>

        <!-- それ以外 -->
        <main class="l-common-contents" v-else>
          <div class="l-common-contents__body" data-background="gray">
            <div class="p-teaching-unit-list__head back">
              <router-link
                :to="{ name: 'MTR001_materials' }"
                class="p-teaching-unit-content__back c-txt"
                >教材一覧に戻る</router-link
              >
              <div class="p-teaching-unit-list__head-title">
                {{ this.contentsData.materialName }}
              </div>
            </div>
            <div class="p-teaching-unit-list__body">
              <div class="p-teaching-unit-list__wrapper">
                <!-- トグルボタンとか -->
                <div class="p-teaching-unit-content__detail">
                  <div class="p-teaching-unit-content__detail__inner">
                    <label
                      class="p-teaching-unit-content__detail__toggle-switch"
                    >
                      <input
                        type="checkbox"
                        id="useToggleBtn"
                        v-on:click="SwitchExcludeUsedContents()"
                        v-model="excludeUsedContents"
                      />
                      <span
                        class="p-teaching-unit-content__detail__slider"
                      ></span>
                    </label>
                    <p class="p-teaching-unit-content__detail__txt c-txt">
                      使用済みコンテンツを非表示
                    </p>
                    <button
                      class="p-teaching-unit-content__detail__info"
                      v-on:mouseover="showInformation()"
                      v-on:mouseleave="hideInformation()"
                    >
                      <img src="/assets/img/common/icon_info-gray.svg" alt="" />
                      <div
                        class="c-block-info"
                        :class="{ 'is-appear': isAppearInfo }"
                      >
                        <p class="c-block-info__txt c-txt--xs">
                          印刷またはダウンロードを行っているコンテンツを非表示にすることができます。
                          すでに印刷やダウンロードを行った教材は、[使用済み]のラベルを表示します。
                        </p>
                      </div>
                    </button>
                  </div>
                  <button
                    class="p-common-contents__btn-all c-txt--xs is-open"
                    v-on:click="accordionAllOpen($event)"
                  >
                    折りたたむ
                  </button>
                </div>
                <div
                  v-for="grade in this.contentsData.grades"
                  :key="grade.gradeName"
                >
                  <div class="p-teaching-unit-list__heading">
                    {{ grade.gradeName }}
                  </div>
                  <div
                    class="p-teaching-unit-list__body__container-semester js-container"
                  >
                    <div class="p-teaching-unit-list__body__container-content">
                      <div
                        class="p-teaching-unit-list__body__content border-none js-unitBody is-open"
                        id="tool01"
                        v-for="unit in grade.units"
                        :key="unit.unitName"
                        style="cursor: default;"
                      >
                        <div
                          class="p-teaching-unit-list__body__container__content-title"
                          v-on:click="accordionOpen($event)"
                          style="cursor: pointer;"
                        >
                          <div
                            class="p-teaching-unit-list__body__content-title c-txt--l c-txt--bold"
                          >
                            <label
                              class="c-checkbox"
                              @click.prevent.stop="materialTypesCheck(unit)"
                            >
                              <input
                                type="checkbox"
                                name="教材名教材名"
                                class="c-checkbox__input js-all"
                                :class="{ 'minus-check': unit.isMinusCheck }"
                                v-model="unit.isCheck"
                              />
                              <span class="c-checkbox__txt">{{
                                unit.unitName
                              }}</span>
                            </label>
                          </div>
                          <div class="p-teaching-unit-list__body__content-box">
                            <div>{{ unit.contents.length }}件</div>
                            <div
                              class="p-teaching-unit-list__body__content-img"
                            ></div>
                          </div>
                        </div>
                        <div 
                          class="p-common-contents__content-outer accordion"
                          style="cursor: default;"
                        >
                          <a
                            class="p-common-contents__container-material"
                            v-for="content in unit.contents"
                            :key="content.contentName"
                            :class="{ lock: !content.visible }"
                            @click="openPreview(content, contentsData.teacherMaterialIncluded)"
                          >
                            <div class="p-common-contents__material-img__wrapper">
                              <div class="p-common-contents__material-img">
                                <label
                                    class="c-checkbox"
                                    v-if="
                                content.contentType === 1 && content.visible
                              "
                                    @click.stop="changeCheckContents(content)"
                                >
                                  <input
                                      type="checkbox"
                                      name=""
                                      class="c-checkbox__input js-single"
                                      :class="{
                                  'minus-check':
                                    (content.forStudent &&
                                      !content.forTeacher &&
                                      content.teacherContentUrl !== '') ||
                                    (!content.forStudent &&
                                      content.forTeacher),
                                }"
                                      v-model="content.isParentCheck"
                                  />
                                  <span class="c-checkbox__txt"> </span>
                                </label>
                                <div
                                  class="c-block-used"
                                  v-if="content.isUsedFlg"
                                >
                                  <div class="c-block-used__txt c-txt--xxs">
                                    使用済み
                                  </div>
                                  <button
                                    type="button"
                                    class="c-block-used__img"
                                    v-on:click.stop="removeUsedContentId(content)"
                                  >
                                    <img
                                      src="/assets/img/common/icon_x.svg"
                                      alt=""
                                    />
                                  </button>
                                </div>
                                <div class="p-common-contents__material-img-innerframe">
                                  <img
                                    v-if="content.studentThumbnailUrl"
                                    v-bind:src="content.studentThumbnailUrl"
                                    onerror="this.src='/assets/img/common/dummy/noimage.png';"
                                    alt=""
                                  />
                                  <img
                                    v-else-if="content.teacherThumbnailUrl"
                                    v-bind:src="content.teacherThumbnailUrl"
                                    onerror="this.src='/assets/img/common/dummy/noimage.png';"
                                    alt=""
                                  />
                                  <img
                                    v-else
                                    src="/assets/img/common/dummy/noimage.png"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div
                                class="p-common-contents__material__icon-double"
                                :style="isCompatibleWithTouch ? 'display: flex' : ''"
                                v-if="
                                  content.contentType === 1 &&
                                  content.visible &&
                                  (content.studentContentUrl !== null ||
                                    content.teacherContentUrl !== null) &&
                                  (content.studentContentUrl
                                    .split('.')
                                    .pop() === PDF ||
                                    content.teacherContentUrl
                                      .split('.')
                                      .pop() === PDF)
                                "
                              >
                                <button
                                  class="p-common-contents__material__icon"
                                  ontouchstart=""
                                  @click.stop="
                                    openPrintPreview(
                                      content,
                                      contentsData.teacherMaterialIncluded
                                    )
                                  ">
                                  <img
                                    src="/assets/img/common/icon_tab_blank-white.svg"
                                    alt=""
                                  />
                                </button>
                                <button
                                  class="p-common-contents__material__icon"
                                  ontouchstart=""
                                  @click.stop="
                                    downloadFile(
                                      content,
                                      contentsData.teacherMaterialIncluded
                                    )
                                  ">
                                  <img
                                    src="/assets/img/common/icon_save-white.svg"
                                    alt=""
                                  />
                                </button>
                              </div>

                              <button
                                class="p-common-contents__material__icon p-common-contents__material__icon-position"
                                :style="isCompatibleWithTouch ? 'display: block' : ''"
                                v-else-if="
                                  content.contentType === 1 && content.visible
                                "
                                @click.stop="
                                  downloadFile(
                                    content,
                                    contentsData.teacherMaterialIncluded
                                  )
                                ">
                                <img
                                  src="/assets/img/common/icon_save-white.svg"
                                  alt=""
                                />
                              </button>

                              <button
                                class="p-common-contents__material__icon p-common-contents__material__icon-position"
                                :style="isCompatibleWithTouch ? 'display: block' : ''"
                                v-else-if="
                                  content.contentType === 2 && content.visible
                                "
                                @click.stop="
                                  CopyToClipboard($event, content.contentUrl)
                                "
                              >
                                <img
                                  src="/assets/img/common/icon_link-white.svg"
                                  alt=""
                                />
                                <span class="p-common-contents__material__hint">
                                  リンクをコピー
                                </span>
                              </button>
                              <div
                                v-if="!content.visible"
                                class="p-common-contents__material__lock-mask"
                                style="border-radius: 16px 16px 0 0;"
                              >
                                <div class="p-common-contents__material__lock">
                                  <img
                                    src="/assets/img/common/icon_lock-white.svg"
                                    alt=""
                                  />
                                </div>
                                <p class="c-txt--xxs">
                                  使用にはシリアルコードの入力が必要です
                                </p>
                              </div>
                            </div>
                            <div class="p-common-contents__material-content">
                              <div
                                class="p-common-contents__material__outer-title"
                              >
                                <p
                                  class="p-common-contents__material__title c-txt--xs"
                                ></p>
                              </div>
                              <p class="p-common-contents__material__txt c-txt">
                                {{ content.description }}
                              </p>
                              <div
                                class="p-common-contents__material__btns"
                                v-if="
                                  content.visible &&
                                  content.contentType === 1 &&
                                  this.contentsData.teacherMaterialIncluded &&
                                  content.teacherContentUrl !== '' &&
                                  content.studentContentUrl !== ''
                                "
                              >
                                <button
                                  class="p-common-contents__material__btn"
                                >
                                  <label
                                    class="c-checkbox"
                                    @click.stop="
                                      changeCheckChildContents(content)
                                    "
                                  >
                                    <input
                                      type="checkbox"
                                      name="児童用"
                                      class="c-checkbox__input js-side"
                                      v-model="content.forStudent"
                                    />
                                    <span class="c-checkbox__txt">児童用</span>
                                  </label>
                                </button>
                                <button
                                  class="p-common-contents__material__btn"
                                >
                                  <label
                                    class="c-checkbox"
                                    @click.stop="
                                      changeCheckChildContents(content)
                                    "
                                  >
                                    <input
                                      type="checkbox"
                                      name="教師用"
                                      class="c-checkbox__input js-side"
                                      v-model="content.forTeacher"
                                    />
                                    <span class="c-checkbox__txt">教師用</span>
                                  </label>
                                </button>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ContentListFooter
            :checkedContents="checkedContents"
            :checkedContentsCount="checkedContentsCount"
            @clearCheckedContents="clearCheckedContents"
            @addUsedContents="addUsedContents"
            @openErrorModal="openErrorModal"
          />

          <AllCheckModal
            ref="allCheckModal"
            :targetAllCheckMaterialTypes="targetAllCheckMaterialTypes"
            @allCheckModalClick="allCheckModalClick"
          >
          </AllCheckModal>
        </main>
      </div>
      <!-- [/l-contents] -->
    </div>
    <!-- [/l-wrapper] -->

    <UpdateAnnouncementModal ref="updateAnnouncementModal" />
    <ErrorModal ref="errorModal" />
  </body>
</template>

<script>
import SideBar from "./SideBar.vue";
import axios from "axios";
// import DB from "../DB.js";
import {
  PDF,
  API_URL,
  MATERIALS,
  LS_KEY_USED_CONTENTS,
  COULD_NOT_GET_SIDEBAR_DATA,
} from "../const.js";
import Storage from "../common/Storage.js";
import LastAccessDate from "../common/LastAccessDate.js";
import StorageExpiration from "@/common/StorageExpiration";
import UpdateAnnouncementModal from "@/components/UpdateAnnouncementModal.vue";
import ContentListFooter from "./ContentListFooter.vue";
import AllCheckModal from "./AllCheckModal.vue";
import HeaderArea from "./Header.vue";
import ErrorModal from "@/components/ErrorModal.vue";
import Cookie from "../common/Cookie.js";
import Methods from "@/common/Methods";
import ContentFile from "@/common/ContentFile";
import saveAs from "file-saver";

export default {
  components: {
    SideBar,
    UpdateAnnouncementModal,
    ContentListFooter,
    AllCheckModal,
    HeaderArea,
    ErrorModal,
  },
  data() {
    return {
      contentsData: [],
      pdf: PDF,
      searching: true,
      contentsCount: 0,
      selectedSubject: Cookie.getOrDefault(Cookie.Key.SELECTED_SUBJECT),
      termType: Number(Cookie.getOrDefault(Cookie.Key.TERM_TYPE)),
      checkedContents: [],
      checkedContentsCount: 0,
      targetAllCheckMaterialTypes: null,
      excludeUsedContents: false,
      PDF: PDF,
      isAppearInfo: false,
      message: COULD_NOT_GET_SIDEBAR_DATA,
      isCompatibleWithTouch: false,
    };
  },
  mounted: function () {
    Methods.checkMoveToTop() ? this.$router.push({ name: "FST001_first" }) : "";
    StorageExpiration.check();
    this.$refs.updateAnnouncementModal.checkUpdate();
    LastAccessDate.update();
    // 端末がタッチ（タップ）に対応しているか確認する
    const touch_event = window.ontouchstart;
    const touch_points = navigator.maxTouchPoints;
    if (touch_event !== undefined || 0 < touch_points) {
      // タッチ対応端末の処理が入る
      this.isCompatibleWithTouch = true;
    }

  },
  methods: {
    // 全て開く/全て閉じるの実装
    accordionAllOpen: (event) => {
      // 親要素を取得
      const wrapper = event.target.closest(".p-teaching-unit-list__wrapper");

      // アコーディオンを取得
      const accordionItems = wrapper.querySelectorAll(
        ".p-teaching-unit-list__body__content"
      );

      // 開いているアコーディオンを配列に入れる
      const openAccordion = Array.from(accordionItems).filter((item) => {
        return item.classList.contains("is-open");
      });

      if (openAccordion.length === accordionItems.length) {
        // 全てのアコーディオンが開いている時の処理を記述
        accordionItems.forEach((accordionItem) => {
          accordionItem.classList.remove("is-open");
        });
        event.target.classList.remove("is-open");
        event.target.innerText = "全て開く";
      } else {
        // 一つでもアコーディオンが閉まっている時の処理を記述
        accordionItems.forEach((accordionItem) => {
          accordionItem.classList.add("is-open");
        });
        event.target.classList.add("is-open");
        event.target.innerText = "折りたたむ";
      }
    },

    // アコーディオン開閉の実装
    accordionOpen: (event) => {
      event.preventDefault();

      // 開閉するアコーディオンを取得
      const targetAccordion = event.target.closest(
        ".p-teaching-unit-list__body__content"
      );
      // アコーディオンの開閉
      targetAccordion.classList.toggle("is-open");

      // 学年のブロックを取得
      const wrapper = event.target.closest(".p-teaching-unit-list__wrapper");
      // アコーディオンを取得
      const accordionItems = wrapper.querySelectorAll(
        ".p-teaching-unit-list__body__content"
      );
      // 開いているアコーディオンを配列に入れる
      const openAccordion = Array.from(accordionItems).filter((item) => {
        return item.classList.contains("is-open");
      });
      // 全て開く/全て閉じるボタンを取得
      const openAllBtn = wrapper.querySelector(".p-common-contents__btn-all");

      if (openAccordion.length === accordionItems.length) {
        // 全てのアコーディオンが開いている時の処理を記述
        openAllBtn.classList.add("is-open");
        openAllBtn.innerText = "折りたたむ";
      } else {
        // 一つでもアコーディオンが閉まっている時の処理を記述
        openAllBtn.classList.remove("is-open");
        openAllBtn.innerText = "全て開く";
      }
    },

    /*
     * サイドバーから検索結果を取得する
     */

    jsonData(data) {
      this.contentsData = data;
      this.searching = false;
      this.contentsCount = 0;
      this.clearCheckedContents();
      this.contentsData.grades.forEach((g) => {
        this.contentsCount += g.units.length;
        g.units.map((u) => {
          u.isCheck = false;
          u.contents.map((c) => {
            c.isParentCheck = false;
            c.forStudent = false;
            c.forTeacher = false;
            c.isUsedFlg = this.isUsed(c.contentId);
          });
        });
      });
      // nextTickでDOMが更新された後に実行
      this.$nextTick(() => {
        this.refreshAllBtn();
      });
    },

    // アコーディオンが全て開いているかどうかを判定する
    refreshAllBtn() {
      // p-teaching-unit-list__body__content border-none js-unitBody is-openクラスを持つ要素を全て取得
      const accordionItem = document.querySelectorAll(
        ".p-teaching-unit-list__body__content.border-none.js-unitBody"
      );
      const openAccordion = Array.from(accordionItem).filter((item) => {
        return item.classList.contains("is-open");
      });
      // 全て開く/全て閉じるボタンを取得
      //p-common-contents__btn-all.c-txt--xs クラスを持つ要素を全て取得
      const openAllBtn = document.querySelectorAll(
        ".p-common-contents__btn-all.c-txt--xs"
      );
      // 全て開いているかどうかを判定する
      if (openAccordion.length === accordionItem.length) {
        // 全てのアコーディオンが開いている時は折りたたむボタンを表示する
        openAllBtn.forEach((openAllBtn) => {
          openAllBtn.classList.add("is-open");
          openAllBtn.innerText = "折りたたむ";
        });
      }
    },

    /*
     * 使用済みコンテンツを非表示にする処理
     */

    SwitchExcludeUsedContents() {
      // 使用済みコンテンツを非表示にするかどうかのフラグを取得する
      this.excludeUsedContents =
        document.getElementById("useToggleBtn").checked;

      // シリアルコードを配列で取得する
      let serialNumbers = Cookie.getOrDefault(Cookie.Key.SERIAL_NUMBERS);

      // 使用済みコンテンツを配列で取得する
      let usedContentIds = this.getUsedContentIdsToArray();

      // セッションストレージに登録されている教科と選択中の教科が違う場合、新たにその教科をセッションストレージに追加する
      // 新たにその教科をセッションストレージに追加する
      // 空のJSONを作成する
      let json = {
        gradeTextbookCompliances: [],
        terms: [],
        subjectName: this.selectedSubject,
        termType: this.termType,
        excludeUsedContents: this.excludeUsedContents,
        usedContentIds: usedContentIds,
        serialNumbers: serialNumbers,
      };

      // JSONデータ作成処理
      // ローカルストレージから教科書準拠を取得する
      let textbooks = Storage.get("textbooks");
      let textbooksData = JSON.parse(textbooks);

      // ローカルストレージの値の中から該当の教科書準拠の学年を取得する
      let targetCompliance = textbooksData.filter(
        (textbook) => textbook.subject == this.selectedSubject
      );

      // ローカルストレージの選択中の学年・教科書準拠を比較して、学年と一致するものオブジェクトを格納する
      // 学年のローカルストレージの値を取得する
      let grades = Storage.get("selected_grades");
      let gradesData = JSON.parse(grades);

      let targetGrades = gradesData.find(
        (grade) => grade.subject == this.selectedSubject
      );

      // gradeTextbookCompliancesに値を追加する
      targetGrades.grades.forEach((grade) => {
        let targetComplianceObject = targetCompliance.find(
          (textbook) => textbook.grade == grade
        );
        if (targetComplianceObject) {
          json["gradeTextbookCompliances"].push({
            textbookComplianceName: targetComplianceObject.textbook,
            gradeName: targetComplianceObject.grade,
          });
        }
      });

      if (json["gradeTextbookCompliances"].length <= 0) {
        delete json["gradeTextbookCompliances"];
      }

      // ローカルストレージから選択した学期を取得する
      let terms = Storage.get("selected_terms");
      terms = JSON.parse(terms);

      // 選択されている学年に対応した学期を取得する
      json["terms"] = terms.find(
        (term) => term.subject == this.selectedSubject
      ).terms;

      // 学期が一つでも指定されていたら、JSONデータに学期を追加する
      if (json["terms"].length <= 0) {
        delete json["terms"];
      }
      const materialTypeId = this.$route.params.materialTypeId;
      let url = API_URL + MATERIALS + "/" + materialTypeId + "/contents";
      axios
        .post(url, json)
        .then((res) => {
          this.contentsData = res.data;
          this.searching = false;
          this.clearCheckedContents();
          this.contentsCount = this.contentsData.grades.length;
          this.contentsData.grades.forEach((g) => {
            this.contentsCount += g.units.length;
            g.units.map((u) => {
              u.isCheck = false;
              u.contents.map((c) => {
                c.isParentCheck = false;
                c.forStudent = false;
                c.forTeacher = false;
                c.isUsedFlg = this.isUsed(c.contentId);
              });
            });
          });
        })
        .catch((error) => {
          console.log("error: " + error);
          this.openErrorModal(error.response.status);
        });
    },

    /*
     * 選択解除モーダルの削除
     */

    clearCheckedContents() {
      this.checkedContents = [];
      this.checkedContentsCount = 0;
      this.contentsData.grades.map((g) => {
        g.units.map((u) => {
          u.isMinusCheck = false;
          u.isCheck = false;
          u.contents.map((c) => {
            c.isParentCheck = false;
            c.forTeacher = false;
            c.forStudent = false;
          });
        });
      });
    },

    /*
     * コンテンツのチェックボックスを変更した時の処理
     */

    updateCheckedContents() {
      this.checkedContentsCount = 0;
      this.checkedContents = [];
      this.contentsData.grades.map((g) => {
        g.units.map((u) => {
          u.contents.map((c) => {
            if (c.isParentCheck) {
              this.checkedContentsCount++;
              if (c.forTeacher && c.forStudent) {
                this.checkedContentsCount++;
              }
              this.checkedContents.push(c);
            }
          });
        });
      });
      this.changeCheckUnits();
    },

    /*
     * コンテンツのチェックボックスを変更した時の処理
     */

    changeCheckContents(content) {
      if (
        // 「−」状態で押下した時の処理(教師・生徒どちらかに値が入っている場合)
        (content.forStudent &&
          !content.forTeacher &&
          content.teacherContentUrl !== "") ||
        (!content.forStudent && content.forTeacher)
      ) {
        // 「−」状態で押下した時に「チェック」状態にする
        setTimeout(() => {
          content.isParentCheck = true;
          // 「チェック」状態で押下した時にURLの有無を代入する
          content.forStudent = content.studentContentUrl !== "";
          content.forTeacher = content.teacherContentUrl !== "";
          this.updateCheckedContents();
        });
      } else {
        setTimeout(() => {
          // チェック状態→未チェック状態
          if (content.isParentCheck) {
            content.isParentCheck = false;
            content.forStudent = false;
            content.forTeacher = false;
          } else {
            // 未チェック状態→チェック状態にした時
            content.isParentCheck = true;
            content.forStudent = content.studentContentUrl !== "";
            content.forTeacher = content.teacherContentUrl !== "";
          }
          this.updateCheckedContents();
        });
      }
    },

    /*
     * コンテンツ内の生徒用・教師用のチェックボックスを変更した時の処理
     */

    changeCheckChildContents(content) {
      setTimeout(() => {
        if (content.forTeacher || content.forStudent) {
          content.isParentCheck = true;
        } else {
          content.isParentCheck = false;
        }
        this.updateCheckedContents();
      });
    },

    /*
     * 単元のチェックボックスを変更した時のチェックボックスの変更処理
     */

    changeCheckUnits() {
      this.contentsData.grades.map((g) => {
        g.units.map((u) => {
          let maxByUnits = 0;
          let checkCountByUnits = 0;
          u.contents.map((c) => {
            // 利用不可・URLコンテンツ以外の時はカウントアップ
            if (c.visible && c.contentType === 1) {
              maxByUnits++;
            }
            // 利用不可・URLコンテンツ以外・教師用のコンテンツが存在しない以外はカウントアップ
            if (
              c.teacherContentUrl !== "" &&
              c.visible &&
              c.contentType === 1
            ) {
              maxByUnits++;
            }
            if (c.isParentCheck) {
              checkCountByUnits++;
              if (c.forTeacher && c.forStudent) {
                checkCountByUnits++;
              }
            }
            u.isCheck = checkCountByUnits > 0;
            u.isMinusCheck =
              checkCountByUnits > 0 && maxByUnits !== checkCountByUnits;
          });
        });
      });
    },

    /*
     * 教材のチェックボックスを変更した時の処理
     */
    materialTypesCheck(unit) {
      if (unit.isCheck) {
        // マイナスチェックが入っている場合は、対象単元のチェックボックスをONにする
        if (unit.isMinusCheck) {
          this.$refs.allCheckModal.showModal = true;
          this.targetAllCheckMaterialTypes = unit;
        } else {
          setTimeout(() => {
            unit.isCheck = false;
            unit.isMinusCheck = false;
            unit.contents.map((c) => {
              c.isParentCheck = false;
              if (c.studentContentUrl !== "") {
                c.forStudent = false;
              }
              if (c.teacherContentUrl !== "") {
                c.forTeacher = false;
              }
            });
            this.updateCheckedContents();
          });
        }
      } else {
        // 単元単位のチェックボックスで、チェック可能なコンテンツがあるかどうか
        const ableToCheckFlg = unit.contents.some((c) => {
          return c.visible && c.contentType === 1;
        }); 
        if (this.contentsData.teacherMaterialIncluded && ableToCheckFlg) {
          this.$refs.allCheckModal.showModal = true;
          this.targetAllCheckMaterialTypes = unit;
        } else {
          unit.contents.map((c) => {
            c.isParentCheck = c.visible && c.contentType === 1;
            if (
              c.studentContentUrl !== "" &&
              c.visible &&
              c.contentType === 1
            ) {
              c.forStudent = true;
            }
            if (
              c.teacherContentUrl !== "" &&
              c.visible &&
              c.contentType === 1
            ) {
              c.forTeacher = true;
            }
          });
          this.updateCheckedContents();
        }
      }
    },

    /**
     * 全チェック確認モーダルのボタン押下
     * @param String[] types ["student", "teacher"]
     */
    allCheckModalClick(types, unit) {
      unit.contents.map((c) => {
        c.isParentCheck = c.visible && c.contentType === 1;
        if (
          c.studentContentUrl !== "" &&
          c.visible &&
          c.contentType === 1 &&
          types.indexOf("student") !== -1
        ) {
          c.forStudent = true;
        }
        if (
          c.teacherContentUrl !== "" &&
          c.visible &&
          c.contentType === 1 &&
          types.indexOf("teacher") !== -1
        ) {
          c.forTeacher = true;
        } // 教師用を選択したが、教師用コンテンツがない場合は、親チェックを外す
        if (c.teacherContentUrl === "" && types.indexOf("teacher") !== -1) {
          c.isParentCheck = false;
        }

        // 「児童用・教師用」(両方)選択した場合は、親チェックをつける
        if (
          types.indexOf("student") !== -1 &&
          types.indexOf("teacher") !== -1 &&
          c.visible &&
          c.contentType === 1
        ) {
          c.isParentCheck = true;
        }
      });
      this.updateCheckedContents();
    },
    /*
     * プレビュー画面を開く
     * @param {object} content コンテンツのオブジェクト
     */
    openPreview(content, teacherMaterialIncluded) {
      if (!content.visible) {
        return;
      }

      if (content.contentType === 2) {
        Methods.openTab(content.contentUrl);
        return;
      }

      const studentChecked = content.forStudent;
      const teacherChecked = content.forTeacher;
      const notChecked = !studentChecked && !teacherChecked;
      const bothChecked = studentChecked && teacherChecked;
      const shouldShowBoth = teacherMaterialIncluded && (notChecked || bothChecked);

      const storageData = [];
      if (content.studentContentUrl && (shouldShowBoth || studentChecked || !teacherMaterialIncluded)) {
        let _c = JSON.parse(JSON.stringify(content));
        _c.forStudent = true;
        storageData.push(_c);
      }
      if (content.teacherContentUrl && (shouldShowBoth || teacherChecked)) {
        let _c = JSON.parse(JSON.stringify(content));
        _c.forStudent = false;
        storageData.push(_c);
      }

      if (teacherMaterialIncluded && shouldShowBoth) {
        Storage.setPreviewListContents(storageData);
        Methods.openTab("/contents/preview");
        return;
      }

      const isOnlyStudent = !teacherMaterialIncluded || studentChecked;
      const fileUrl = isOnlyStudent ? content.studentContentUrl : content.teacherContentUrl;

      if (ContentFile.isPdf(fileUrl)) {
        this.addUsedContents(content.contentId);
        Methods.openTab(fileUrl);
        return;
      }

      if (ContentFile.isMovie(fileUrl)) {
        Storage.setVideoPreviewContents(storageData);
        Methods.openTab("/contents/preview_mv");
        return;
      }

      if (ContentFile.isExcel(fileUrl)) {
        Storage.setPreviewListContents(storageData);
        Methods.openTab("/contents/preview");
      }
    },
    /*
     * 使用済みコンテンツをローカルストレージに格納する
     * @param {Number} contentId コンテンツID
     */
    isUsed(contentId) {
      let usedContentIds = this.getUsedContentIdsToArray();
      return usedContentIds && usedContentIds.includes(contentId);
    },

    /*
     * 使用済みコンテンツをローカルストレージに格納する
     */

    addUsedContents(contentIds) {
      if (!Array.isArray(contentIds)) contentIds = [contentIds];
      let usedContentIds = this.getUsedContentIdsToArray();
      usedContentIds = usedContentIds.concat(contentIds);
      usedContentIds = usedContentIds.filter(function (value, index, self) {
        return self.indexOf(value) === index;
      });
      Storage.set(LS_KEY_USED_CONTENTS, usedContentIds);
      // 使用済みフラグの更新
      this.contentsData.grades.forEach((g) => {
        g.units.map((u) => {
          u.contents.map((c) => {
            if (usedContentIds.includes(c.contentId)) {
              c.isUsedFlg = true;
            }
          });
        });
      });
    },

    /*
     * ダウンロードアイコンと印刷アイコン押下時のPOSTデータ作成処理
     * チェックボックスの押下状況に応じて、POSTデータを作成する
     * @param {object} contents - アイコンクリック時の対象のコンテンツ
     * return {object} postData - POSTデータ
     */

    createPostData(contents, teacherMaterialIncluded) {
      let postData = [];
      // 親チェック状態によって、送るデータを変更する
      // [1]コンテンツの親チェックが入っていない
      if (!contents.isParentCheck) {
        // ①表示区分がfalseの場合は、児童用のみのコンテンツをtrueで送る
        if (!teacherMaterialIncluded) {
          // 児童用のみ格納
          postData.push({
            contentId: contents.contentId,
            forStudent: true,
          });
        } else {
          // ②表示区分がtrueの場合は、児童用・教師用のコンテンツ両方を送る
          // 児童用格納
          postData.push({
            contentId: contents.contentId,
            forStudent: true,
          });
          // 教師用格納
          postData.push({
            contentId: contents.contentId,
            forStudent: false,
          });
        }
      } else {
        // [2]親チェックが入っている場合(マイナスチェックも含む)
        // ①表示区分がfalseの場合は、児童用のみのコンテンツをtrueで送る
        if (!teacherMaterialIncluded) {
          // 児童用のみ格納
          postData.push({
            contentId: contents.contentId,
            forStudent: true,
          });
        } else {
          // ②表示区分がtrueの場合は、各チェック状況を確認する
          // 児童用にチェックが入っている場合
          // 児童用のデータを格納
          if (contents.forStudent) {
            postData.push({
              contentId: contents.contentId,
              forStudent: true,
            });
          }
          // 教師にチェックが入っている場合
          // 教師のデータを格納
          if (contents.forTeacher) {
            postData.push({
              contentId: contents.contentId,
              forStudent: false,
            });
          }
        }
      }
      return postData;
    },

    /*
     * 印刷アイコンボタンを押した時の処理
     * @param {object} contents - アイコンクリック時の対象のコンテンツ
     */
    openPrintPreview(contents, teacherMaterialIncluded) {
      this.openMergedPdfContentsView(contents, teacherMaterialIncluded, () => {
        Storage.addContentsIdToLocalstrage(contents.contentId);
        contents.isUsedFlg = true;
      });
    },
    /**
     * 複数のPDFコンテンツを連結して開く
     */
    openMergedPdfContentsView(content, teacherMaterialIncluded, callback = () => {}) {
      // チェック状況に応じてPOSTデータを作成
      let postData = this.createPostData(content, teacherMaterialIncluded);
      const params = { contents: postData };
      axios
        .post(API_URL + "/pdf/merge", params)
        .then((res) => {
          callback();
          Methods.openTab(res.data.pdfFileUrl);
        })
        .catch((error) => {
          console.log("error: " + error);
          this.openErrorModal(error.response.status);
        });
    },
    /*
     * ダウンロードアイコンボタンを押した時の処理
     * @param {object} contents - アイコンクリック時の対象のコンテンツ
     */

    downloadFile(contents, teacherMaterialIncluded) {
      // チェック状況に応じてPOSTデータを作成
      let postData = this.createPostData(contents, teacherMaterialIncluded);
      const params = { contents: postData };
      axios
        .post(API_URL + "/contents/download", params, { responseType: "blob" })
        .then((res) => {
          const mineType = res.headers["content-type"];
          const name = this.getDownloadFileName(res.headers["content-disposition"]);
          const blob = new Blob([res.data], { type: mineType });
          saveAs(blob, name);

          Storage.addContentsIdToLocalstrage(contents.contentId);
          contents.isUsedFlg = true;
        })
        .catch((error) => {
          console.log("error: " + error);
          this.openErrorModal(error.response.status);
        });
    },
    // content-dispositionからファイル名だけを取得
    getDownloadFileName(value) {
      if (value == undefined) {
        return "";
      }
      const splitted = value.split("filename=");
      if (splitted.length < 2) {
        return '';
      }
      return decodeURI(splitted[1].replace(/"/g, ''));
    },

    /*
     * アイコンをクリックした時にコピーする
     */

    CopyToClipboard(event, contentUrl) {
      // タッチデバイスの場合は、ヒントを表示する
      if(!window.matchMedia('(any-hover:hover)').matches) {
        // clickedElement はbutton要素、またはその内部のimgやspan要素
        let clickedElement = event.target;
        // button要素の内部にある `.p-common-contents__material__hint` クラスを持つ要素を探す
        let hint = clickedElement.querySelector('.p-common-contents__material__hint') || 
          clickedElement.closest('button').querySelector('.p-common-contents__material__hint');
        hint.style.display = 'block';
        setTimeout(() => {
          hint.style.display = 'none';
        },2000);
      }
      
      navigator.clipboard.writeText(contentUrl);
    },
    /*
     * クリック時に使用済みコンテンツIDを削除する
     * @param {number} contentId
     */
    removeUsedContentId(content) {
      // 使用済みコンテンツIDを取得する
      let usedContentIds = Storage.get(LS_KEY_USED_CONTENTS);
      // 使用済みIDが存在したら、配列に格納する
      if (usedContentIds) {
        usedContentIds = usedContentIds.split(",");
        let intUsedContentIds = usedContentIds.map((str) => parseInt(str, 10));
        // 配列内に存在するcontentIdを削除する(削除した配列を新たに作成)
        let removedUsedContentIds = intUsedContentIds.filter(
          (id) => id !== content.contentId
        );
        // 削除した配列をローカルストレージに格納する
        Storage.set(LS_KEY_USED_CONTENTS, removedUsedContentIds);
        // 使用済みラベルを非表示にする
        content.isUsedFlg = false;
      }
    },
    /*
     * 使用済みコンテンツをローカルストレージから配列で取得する
     * @return {Array} usedContentIds 使用済みコンテンツの配列
     */

    getUsedContentIdsToArray() {
      // 使用済みコンテンツを取得
      let usedContentIds = Storage.get(LS_KEY_USED_CONTENTS);
      if (usedContentIds) {
        usedContentIds = usedContentIds.split(",");
        usedContentIds = usedContentIds.map((str) => parseInt(str, 10));
      } else {
        usedContentIds = [];
      }
      return usedContentIds;
    },
    /*
     * information「(i)アイコン」をホバーした時にコンテンツを表示
     */

    showInformation() {
      this.isAppearInfo = true;
    },

    /*
     * information「(i)アイコン」をホバーした時にコンテンツを表示
     */
    hideInformation() {
      this.isAppearInfo = false;
    },
    /*
     * 改行コードを<br>に変換する
     * @param {string} msg
     * @return {string} msg
     */

    htmlText(message) {
      if (message) {
        return message.replace(/\r?\n/g, "<br>");
      }
    },

    /**
     * エラーモーダルを開く
     */
    openErrorModal(statusCode) {
      this.$refs.errorModal.open(statusCode);
    },
  },
};
</script>
