<template>
  <div
    class="l-common-contents__mask modal"
    :class="{ 'is-appear': showModal }"
    id="errorModal"
    style="z-index: 10000"
  >
    <div class="c-modal info">
      <div class="c-modal__head">
        <div class="c-modal__title info">
          {{ title[statusCode] || title["500"] }}
        </div>
      </div>
      <div class="c-modal__body">
        <p class="c-txt" style="white-space: pre-wrap; word-wrap: break-word">
          {{ message[statusCode] || message["500"] }}
        </p>
      </div>
      <div class="c-modal__foot">
        <button
          type="button"
          class="c-btn c-btn--l c-btn--bg-primary c-txt--l modalClose"
          @click="onClickButton()"
        >
          <span>{{ button[statusCode] || button["500"] }}</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ErrorModal",
  data() {
    return {
      showModal: false,
      statusCode: 500,
      title: {
        "400": "お探しのページは見つかりませんでした。",
        "500": "現在サービスをご利用いただくことはできません。",
      },
      message: {
        "400": "アクセスしようとしたページは削除された、もしくはURLが変更された可能性があります。",
        "500": "恐れ入りますが、しばらく時間をおいてお試しください。",
      },
      button: {
        "400": "閉じる",
        "500": "トップに戻る",
      },
    };
  },
  methods: {
    open(statusCode) {
      this.statusCode = statusCode || "500";
      this.showModal = true;
    },
    close() {
      this.showModal = false;
    },
    onClickButton() {
      if (this.statusCode == "400") {
        this.close();
        return;
      }

      this.$router.push({ name: "FST001_first" });
    },
  },
};
</script>
