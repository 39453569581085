import { LS_SUFFIX_EXPIRES_ON } from "@/const";

const StorageExpiration = {
    check() {
        const removeKeys = [];

        for (let i = 0; i < localStorage.length; i++) {
            // 有効期限のキーでない場合はスキップ
            const key = localStorage.key(i);
            if (!key.endsWith(LS_SUFFIX_EXPIRES_ON)) {
                continue;
            }

            // 有効期限が取得できない場合はスキップ
            const expireDate = localStorage.getItem(key);
            if (!expireDate) {
                continue;
            }

            // 有効期限が過ぎている場合は有効期限のキーと対応するキーを削除対象に追加
            const today = new Date();
            const todayDate = [today.getFullYear(), String(today.getMonth() + 1).padStart(2, "0"), String(today.getDate()).padStart(2, "0")];
            const todayString = todayDate.join("");
            if (parseInt(expireDate.split("-").join("")) < parseInt(todayString)) {
                removeKeys.push(key);
                removeKeys.push(key.replace(LS_SUFFIX_EXPIRES_ON, ""));
            }
        }

        // 削除対象のキーを削除
        for (const key of removeKeys) {
            localStorage.removeItem(key);
        }
    },
};

export default StorageExpiration;
