import {
    LS_KEY_USED_CONTENTS,
    LS_KEY_USED_CONTENTS_EXPIRES_ON,
    LS_SUFFIX_EXPIRES_ON,
} from "../const.js";

const Storage = {
    Key: {
        TEXTBOOKS: "textbooks",
        USED_CONTENTS: "used_contents",
        SELECTED_TERMS: "selected_terms",
        SELECTED_GRADES: "selected_grades",
        SELECTED_SCREEN_GROUPS: "selected_screen_group",
        PREVIEW_CONTENTS_LIST: "preview_contents_list",
        PREVIEW_CONTENTS_VIDEO: "preview_contents_video",
    },

    init: function () {},
  
    load: function (param, callback) {
      callback();
    },

    get: function(key) {
        const expireDate = localStorage.getItem(key + LS_SUFFIX_EXPIRES_ON);
        if (expireDate) {
            const today = new Date();
            const todayDate = [today.getFullYear(), today.getMonth() + 1, today.getDate()];
            const todayString = todayDate.join("");
            if (parseInt(expireDate.split("-").join("")) < parseInt(todayString)) {
                // 期限切れのため削除して未作成の扱いとする
                localStorage.removeItem(key);
                localStorage.removeItem(key + LS_SUFFIX_EXPIRES_ON);
                return null;
            }
        }
        return localStorage.getItem(key);
    },

    set: function(key, value) {
        // 年度末の日付取得
        const today = new Date();
        const limitDate = String(today.getMonth() >= 3 ? today.getFullYear() + 1 : today.getFullYear()) + "-03-31";
        localStorage.setItem(key + LS_SUFFIX_EXPIRES_ON, limitDate);

        localStorage.setItem(key, value);
    },

    getJson(key) {
        const value = this.get(key);
        if (value === null) {
            return null;
        }

        if (value === "") {
            return null;
        }

        return JSON.parse(value);
    },

    setJson(key, value) {
        this.set(key, JSON.stringify(value));
    },

    /*
    * LocalStorageから使用済みコンテンツIDを取得、配列形式に変換し返却する
    * 同ストレージが期限切れの場合はクリアして未作成の状態で返却する
    *
    * Param   : なし
    * Returns : Array[String] 使用済みとなったコンテンツID
    */
    loadContentsIdFromLocalStorage() {
        // LocalStorageから取得
        let storageIds = localStorage.getItem(LS_KEY_USED_CONTENTS);
        let expireDate = localStorage.getItem(LS_KEY_USED_CONTENTS_EXPIRES_ON);

        // 現在日付を取得し、有効期間内かチェックする
        let today = new Date();
        let todayDate = [today.getFullYear(), today.getMonth() + 1, today.getDate()];
        let todayString = todayDate.join("");
        if (storageIds == undefined || expireDate == undefined) {
            // LocalStorage未作成
            return [];
        } else if (parseInt(expireDate.split("-").join("")) < parseInt(todayString)) {
            // 期限切れのため削除して未作成の扱いとする
            localStorage.removeItem(LS_KEY_USED_CONTENTS);
            localStorage.removeItem(LS_KEY_USED_CONTENTS_EXPIRES_ON);
            return [];
        }
        return storageIds.split(',');
    },

    /*
     * LocalStorageの使用済みコンテンツIDにパラメータのコンテンツIDを追加する（重複時はスルーする）
     * 同ストレージが未作成の場合は新規作成する
     *
     * Param   : int コンテンツID
     * Returns : なし
     */
    addContentsIdToLocalstrage(contentId) {
        // 年度末の日付取得
        let today = new Date();
        const limitDate = String(today.getMonth() >= 3 ? today.getFullYear() + 1 : today.getFullYear()) + "-03-31";

        // 登録対象のコンテンツIDの重複チェックと登録
        let idList = this.loadContentsIdFromLocalStorage();
        if (idList == 0) {
            localStorage.setItem(LS_KEY_USED_CONTENTS, contentId);
            localStorage.setItem(LS_KEY_USED_CONTENTS_EXPIRES_ON, limitDate);
        } else if (idList.indexOf(String(contentId)) == -1) {
            idList.push(contentId);
            localStorage.setItem(LS_KEY_USED_CONTENTS, idList.join());
            localStorage.setItem(LS_KEY_USED_CONTENTS_EXPIRES_ON, limitDate);
        }
    },

    /**
     * プレビュー一覧画面で表示させる対象のデータをセットする
     * 
     * Param   : Array 表示対象のデータ
     * Returns : なし
     */
    setPreviewListContents(target) {
        let data = [];
        target.forEach((item) => {
            if (item.contentId != undefined) {
                const wrk = {
                        "contentId": item.contentId,
                        "isStudent": (item.forStudent == true),
                        "isTeacher": (item.forStudent == true ? false : true)
                };
                data.push(wrk);
            }
        });
        localStorage.setItem(this.Key.PREVIEW_CONTENTS_LIST, JSON.stringify(data));
    },

    /**
     * プレビュー一覧・動画画面での表示対象を取得する
     * 取得と同時にLocalStorageは削除される
     * 
     * Param   : なし
     * Returns : Array 表示対象のデータ
     */
    getPreviewListContents() {
        const result = localStorage.getItem(this.Key.PREVIEW_CONTENTS_LIST);
        if (result == undefined) {
            return [];
        }
        return JSON.parse(result);
    },

    /**
     * プレビュー動画画面で表示させる対象のデータをセットする
     * 
     * Param   : Array 表示対象のデータ
     * Returns : なし
     */
    setVideoPreviewContents(target) {
        let data = [];
        target.forEach((item) => {
            if (item.contentId != undefined) {
                const wrk = {
                        "contentId": item.contentId,
                        "isStudent": (item.forStudent == true),
                        "isTeacher": (item.forStudent == true ? false : true)
                };
                data.push(wrk);
            }
        });
        localStorage.setItem(this.Key.PREVIEW_CONTENTS_VIDEO, JSON.stringify(data));
    },

    /**
     * プレビュー一覧・動画画面での表示対象を取得する
     * 取得と同時にLocalStorageは削除される
     * 
     * Param   : なし
     * Returns : Array 表示対象のデータ
     */
    getVideoPreviewContents() {
        const result = localStorage.getItem(this.Key.PREVIEW_CONTENTS_VIDEO);
        if (result == undefined) {
            return [];
        }
        return JSON.parse(result);
    },
};

export default Storage;
