<!--
    ERR001_error.vue
    汎用エラー
 -->
<template>
    <body class="input">
        <div class="l-wrapper">

<header class="l-header settings">
    <div class="l-header__container">
        <div class="l-header__container-logo">
            <h1 class="l-header__logo">
                <a href="../../" class="l-header__logo-link">
                    <img
                        src="/assets/img/settings/header/icon_setings-logo.svg"
                        alt="スマナビ"
                    />
                </a>
            </h1>
        </div>
    </div>
</header>


            <main>
                <div class="p-error__container">
                    <div class="p-error__inner" data-background="gray">
                        <div class="p-error__content" data-background="white">
                            <div class="p-error__title c-txt--l">
                                お探しのページは見つかりませんでした。
                            </div>
                            <p class="p-error__txt c-txt">
                                アクセスしようとしたページは削除された、もしくはURLが変更された可能性があります。
                            <br />
                                トップから再度お探しください。
                            </p>
                        </div>
                    </div>
                </div>
                <a
                    class="c-btn c-btn--l c-btn--bg-primary c-txt--l c-btn--l"
                    @click="$router.push('/')"
                >
                    <span>トップに戻る</span>
                </a>
            </main>
            <!-- [/l-contents] -->
        </div>
        <!-- [/l-wrapper] -->

    </body>
</template>
