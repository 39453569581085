const DB = {
  init: function () {},

  load: function (param, callback) {
    callback();
  },

  callApi: function () {
    let grades = [
      {
        gradeName: "1年",
        terms: [
          {
            termName: "1学期",
            units: [
              {
                unitId: 1,
                unitName: "まいたけぐん",
                materialTypes: [
                  { materialTypeId: 1, materialName: "漢字プリント" },
                  { materialTypeId: 2, materialName: "補充プリント" },
                  { materialTypeId: 3, materialName: "漢字プリント" },
                  { materialTypeId: 4, materialName: "補充プリント" },
                  { materialTypeId: 5, materialName: "漢字プリント" },
                  { materialTypeId: 6, materialName: "補充プリント" },
                ],
              },
              {
                unitId: 2,
                unitName: "きのこぐん",
                materialTypes: [
                  { materialTypeId: 1, materialName: "漢字プリント" },
                  { materialTypeId: 2, materialName: "補充プリント" },
                  { materialTypeId: 3, materialName: "漢字プリント" },
                  { materialTypeId: 4, materialName: "補充プリント" },
                  { materialTypeId: 5, materialName: "漢字プリント" },
                  { materialTypeId: 6, materialName: "補充プリント" },
                ],
              },
              {
                unitId: 3,
                unitName: "まつたけぐん",
                materialTypes: [
                  { materialTypeId: 1, materialName: "漢字プリント" },
                  { materialTypeId: 2, materialName: "補充プリント" },
                  { materialTypeId: 3, materialName: "漢字プリント" },
                  { materialTypeId: 4, materialName: "補充プリント" },
                  { materialTypeId: 5, materialName: "漢字プリント" },
                  { materialTypeId: 6, materialName: "補充プリント" },
                ],
              },
              {
                unitId: 4,
                unitName: "しいたけぐん",
                materialTypes: [
                  { materialTypeId: 1, materialName: "漢字プリント" },
                  { materialTypeId: 2, materialName: "補充プリント" },
                  { materialTypeId: 3, materialName: "漢字プリント" },
                  { materialTypeId: 4, materialName: "補充プリント" },
                  { materialTypeId: 5, materialName: "漢字プリント" },
                  { materialTypeId: 6, materialName: "補充プリント" },
                ],
              },
              {
                unitId: 5,
                unitName: "えのきぐん",
                materialTypes: [
                  { materialTypeId: 1, materialName: "漢字プリント" },
                  { materialTypeId: 2, materialName: "補充プリント" },
                  { materialTypeId: 3, materialName: "漢字プリント" },
                  { materialTypeId: 4, materialName: "補充プリント" },
                  { materialTypeId: 5, materialName: "漢字プリント" },
                  { materialTypeId: 6, materialName: "補充プリント" },
                ],
              },
              {
                unitId: 6,
                unitName: "しめじぐん",
                materialTypes: [
                  { materialTypeId: 1, materialName: "漢字プリント" },
                  { materialTypeId: 2, materialName: "補充プリント" },
                  { materialTypeId: 3, materialName: "漢字プリント" },
                  { materialTypeId: 4, materialName: "補充プリント" },
                  { materialTypeId: 5, materialName: "漢字プリント" },
                  { materialTypeId: 6, materialName: "補充プリント" },
                ],
              },
            ],
          },
          {
            termName: "2学期",
            units: [
              {
                unitId: 2,
                unitName: "きのこぐん",
                materialTypes: [
                  { materialTypeId: 2, materialName: "補充プリント" },
                ],
              },
            ],
          },
          {
            termName: "3学期",
            units: [
              {
                unitId: 3,
                unitName: "たけのこぐん",
                materialTypes: [
                  { materialTypeId: 3, materialName: "漢字プリント" },
                ],
              },
            ],
          },
        ],
      },
      {
        gradeName: "2年",
        terms: [
          {
            termName: "1学期",
            units: [
              {
                unitId: 1,
                unitName: "まいたけぐん",
                materialTypes: [
                  { materialTypeId: 1, materialName: "漢字プリント" },
                  { materialTypeId: 2, materialName: "補充プリント" },
                  { materialTypeId: 3, materialName: "漢字プリント" },
                  { materialTypeId: 4, materialName: "補充プリント" },
                  { materialTypeId: 5, materialName: "漢字プリント" },
                  { materialTypeId: 6, materialName: "補充プリント" },
                ],
              },
              {
                unitId: 2,
                unitName: "きのこぐん",
                materialTypes: [
                  { materialTypeId: 1, materialName: "漢字プリント" },
                  { materialTypeId: 2, materialName: "補充プリント" },
                  { materialTypeId: 3, materialName: "漢字プリント" },
                  { materialTypeId: 4, materialName: "補充プリント" },
                  { materialTypeId: 5, materialName: "漢字プリント" },
                  { materialTypeId: 6, materialName: "補充プリント" },
                ],
              },
              {
                unitId: 3,
                unitName: "まつたけぐん",
                materialTypes: [
                  { materialTypeId: 1, materialName: "漢字プリント" },
                  { materialTypeId: 2, materialName: "補充プリント" },
                  { materialTypeId: 3, materialName: "漢字プリント" },
                  { materialTypeId: 4, materialName: "補充プリント" },
                  { materialTypeId: 5, materialName: "漢字プリント" },
                  { materialTypeId: 6, materialName: "補充プリント" },
                ],
              },
              {
                unitId: 4,
                unitName: "しいたけぐん",
                materialTypes: [
                  { materialTypeId: 1, materialName: "漢字プリント" },
                  { materialTypeId: 2, materialName: "補充プリント" },
                  { materialTypeId: 3, materialName: "漢字プリント" },
                  { materialTypeId: 4, materialName: "補充プリント" },
                  { materialTypeId: 5, materialName: "漢字プリント" },
                  { materialTypeId: 6, materialName: "補充プリント" },
                ],
              },
              {
                unitId: 5,
                unitName: "えのきぐん",
                materialTypes: [
                  { materialTypeId: 1, materialName: "漢字プリント" },
                  { materialTypeId: 2, materialName: "補充プリント" },
                  { materialTypeId: 3, materialName: "漢字プリント" },
                  { materialTypeId: 4, materialName: "補充プリント" },
                  { materialTypeId: 5, materialName: "漢字プリント" },
                  { materialTypeId: 6, materialName: "補充プリント" },
                ],
              },
              {
                unitId: 6,
                unitName: "しめじぐん",
                materialTypes: [
                  { materialTypeId: 1, materialName: "漢字プリント" },
                  { materialTypeId: 2, materialName: "補充プリント" },
                  { materialTypeId: 3, materialName: "漢字プリント" },
                  { materialTypeId: 4, materialName: "補充プリント" },
                  { materialTypeId: 5, materialName: "漢字プリント" },
                  { materialTypeId: 6, materialName: "補充プリント" },
                ],
              },
            ],
          },
          {
            termName: "2学期",
            units: [
              {
                unitId: 2,
                unitName: "きのこぐん",
                materialTypes: [
                  { materialTypeId: 2, materialName: "補充プリント" },
                ],
              },
            ],
          },
          {
            termName: "3学期",
            units: [
              {
                unitId: 3,
                unitName: "たけのこぐん",
                materialTypes: [
                  { materialTypeId: 3, materialName: "漢字プリント" },
                ],
              },
            ],
          },
        ],
      },
    ];
    // { gradeName: "2年生", terms: [{}] },
    // { gradeName: "3年生", terms: [{}] },
    // { gradeName: "4年生", terms: [{}] },
    // { gradeName: "5年生", terms: [{}] },
    // { gradeName: "6年生", terms: [{}] },
    return grades;
  },

  getContentsData: function () {
    let data = {
      unitName: "まいたけぐん",
      gradeName: "1年",
      termName: "1学期",
      materialTypes: [
        {
          materialName: "漢字プリント",
          contents: [
            {
              contentId: 1,
              description: "漢字プリントの説明",
              thumbnail: "/assets/img/common/dummy/noimage.png",
              contentType: 2,
              contentUrl: "/assets/img/common/dummy/noimage.png",
              studentContentFilePath: "/assets/img/common/dummy/noimage.png",
              teacherContentFilePath: null,
              visible: true,
            },
            {
              contentId: 2,
              description: "いよいよ漢字レッスン",
              thumbnail: "/assets/img/common/dummy/noimage.png",
              contentType: 2,
              contentUrl: "/assets/img/common/dummy/noimage.png",
              studentContentFilePath: null,
              teacherContentFilePath: "/assets/img/common/dummy/noimage.png",
              visible: false,
            },
            {
              contentId: 3,
              description: "漢字レッスン始まるよ",
              thumbnail: "/assets/img/common/dummy/noimage.png",
              contentType: 1,
              contentUrl: "/assets/img/common/dummy/noimage.png",
              studentContentFilePath: null,
              teacherContentFilePath: "/assets/img/common/dummy/noimage.png",
              visible: true,
            },
          ],
        },
        {
          materialName: "補充プリント",
          contents: [
            {
              contentId: 4,
              description: "補充プリントの説明",
              thumbnail: "/assets/img/common/dummy/noimage.png",
              contentType: 2,
              contentUrl: "/assets/img/common/dummy/noimage.png",
              studentContentFilePath: "/assets/img/common/dummy/noimage.png",
              teacherContentFilePath: "/assets/img/common/dummy/noimage.png",
              visible: true,
            },
            {
              contentId: 5,
              description: "補充プリントを使ってみよう",
              thumbnail: "/assets/img/common/dummy/noimage.png",
              contentType: 2,
              contentUrl: "/assets/img/common/dummy/noimage.png",
              studentContentFilePath: "/assets/img/common/dummy/noimage.png",
              teacherContentFilePath: "/assets/img/common/dummy/noimage.png",
              visible: true,
            },
            {
              contentId: 6,
              description: "補充プリント1",
              thumbnail: "/assets/img/common/dummy/noimage.png",
              contentType: 2,
              contentUrl: "/assets/img/common/dummy/noimage.png",
              studentContentFilePath: "/assets/img/common/dummy/noimage.png",
              teacherContentFilePath: "/assets/img/common/dummy/noimage.png",
              visible: true,
            },
          ],
        },
      ],
    };
    return data;
  },

  getMaterialData: function () {
    let materialTypes = [
      {
        materialTypeId: 1,
        materialName: "漢字プリント",
        description: "漢字プリントの説明",
        imageUrl: "/assets/img/common/dummy/noimage.png",
        materialIcons: [
          {
            iconType: "授業",
          },
          {
            iconType: "宿題",
          },
          {
            iconType: "復習",
          },
        ],
      },
      {
        materialTypeId: 2,
        materialName: "漢字プリント1",
        description: "漢字プリントの説明をします",
        imageUrl: "/assets/img/common/dummy/noimage.png",
        materialIcons: [
          {
            iconType: "授業",
          },
          {
            iconType: "宿題",
          },
          {
            iconType: "復習",
          },
        ],
      },
      {
        materialTypeId: 3,
        materialName: "漢字プリント2",
        description: "漢字プリントの実践をします",
        imageUrl: "/assets/img/common/dummy/noimage.png",
        materialIcons: [
          {
            iconType: "授業",
          },
          {
            iconType: "宿題",
          },
          {
            iconType: "復習",
          },
        ],
      },
      {
        materialTypeId: 4,
        materialName: "漢字プリント3",
        description: "漢字プリントテスト",
        imageUrl: "/assets/img/common/dummy/noimage.png",
        materialIcons: [
          {
            iconType: "授業",
          },
          {
            iconType: "宿題",
          },
          {
            iconType: "復習",
          },
        ],
      },
    ];
    return materialTypes;
  },
  getMaterialContents: function () {
    let contentsData = {
      materialName: "漢字プリント",
      grades: [
        {
          gradeName: "1年生",
          units: [
            {
              unitName: "まいたけぐん",
              contents: [
                {
                  contentId: 1,
                  description: "漢字プリントの説明",
                  thumbnailUrl: "/assets/img/common/dummy/noimage.png",
                  studentContentFilePath:
                    "/assets/img/common/dummy/noimage.png",
                  teacherContentFilePath:
                    "/assets/img/common/dummy/noimage.png",
                  visible: true,
                },
              ],
            },
          ],
        },
        {
          gradeName: "2年生",
          units: [
            {
              unitName: "しいたけぐん",
              contents: [
                {
                  contentId: 2,
                  description: "漢字プリントの説明",
                  thumbnailUrl: "/assets/img/common/dummy/noimage.png",
                  studentContentFilePath:
                    "/assets/img/common/dummy/noimage.png",
                  teacherContentFilePath:
                    "/assets/img/common/dummy/noimage.png",
                  visible: false,
                },
              ],
            },
          ],
        },
      ],
    };
    return contentsData;
  },
  getContent: function () {
    let contents = [
      {
        contentId: 1,
        gradeName: "1年生",
        unitName: "まいたけぐん",
        description: "漢字プリントの説明",
        thumbnailUrl: "/assets/img/common/dummy/noimage.png",
        studentContentFilePath: "/assets/img/common/dummy/noimage.png",
        teacherContentFilePath: "/assets/img/common/dummy/noimage.png",
        visible: true,
      },
      {
        contentId: 2,
        gradeName: "2年生",
        unitName: "しいたけぐん",
        description: "漢字プリントの応用",
        thumbnailUrl: "/assets/img/common/dummy/noimage.png",
        studentContentFilePath: "/assets/img/common/dummy/noimage.png",
        teacherContentFilePath: "/assets/img/common/dummy/noimage.png",
        visible: false,
      },
    ];
    return contents;
  },
};

export default DB;
