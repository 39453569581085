<!-- 
    TRM001_terms-use.vue
    利用規約
 -->
<script>

export default {
    name: "TermsArea",
    data() {
        return {
        };
    },
    methods: {
    },
};

</script>

<template>
        <div class="l-common-contents__mask modal" id="modal-term">
            <div class="c-modal terms-use">
                <div class="c-modal__head terms-use">
                    <div class="c-modal__title">利用規約</div>
                </div>
                <div class="c-modal__body">
                    <div class="p-terms-use__txt c-txt">
                        <p class="p-terms-use__exa c-txt--s">教師用ポータルサイト『スマナビ』利用規約</p>
                        <p class="p-terms-use__exa c-txt--s">
                            この利用規約（以下、「本規約」といいます。）は、青葉出版株式会社（以下、「当社」といいます。）がこのウェブサイト上で提供するサービス（以下、「本サービス」といいます。）の利用条件を定めるものです。本サービスを利用するユーザー（以下、「ユーザー」といいます。）には、本規約に従って、本サービスをご利用いただきます。
                        </p>
                        <div class="c-txt--l">第1条（適用）</div>
                        <ol class="p-terms-use__list">
                            <li class="p-terms-use__item">
                                本規約は、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。
                            </li>
                            <li class="p-terms-use__item">
                                当社は本サービスに関し、本規約のほか、ご利用にあたってのルール等、各種の定め（以下、「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず、本規約の一部を構成するものとします。
                            </li>
                            <li class="p-terms-use__item">
                                本規約の規定が前条の個別規定の規定と矛盾する場合には、個別規定において特段の定めなき限り、個別規定の規定が優先されるものとします。
                            </li>
                        </ol>
                        <div class="c-txt--l">第2条（禁止事項）</div>
                        <p>
                            ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。
                        </p>
                        <ol class="p-terms-use__list">
                            <li class="p-terms-use__item">
                                法令または公序良俗に違反する行為
                            </li>
                            <li class="p-terms-use__item">犯罪行為に関連する行為</li>
                            <li class="p-terms-use__item">
                                本サービスの内容等、本サービスに含まれる著作権、商標権ほか知的財産権を侵害する行為
                            </li>
                            <li class="p-terms-use__item">
                                当社、ほかのユーザー、またはその他第三者のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為
                            </li>
                            <li class="p-terms-use__item">
                                本サービスによって得られた情報を商業的に利用する行為
                            </li>
                            <li class="p-terms-use__item">
                                当社のサービスの運営を妨害するおそれのある行為
                            </li>
                            <li class="p-terms-use__item">
                                不正アクセスをし、またはこれを試みる行為
                            </li>
                            <li class="p-terms-use__item">
                                不正な目的を持って本サービスを利用する行為
                            </li>
                            <li class="p-terms-use__item">
                                本サービスの他のユーザーまたはその他の第三者に不利益、損害、不快感を与える行為
                            </li>
                            <li class="p-terms-use__item">
                                他のユーザーに成りすます行為
                            </li>
                            <li class="p-terms-use__item">
                                当社が許諾しない本サービス上での宣伝、広告、勧誘、または営業行為
                            </li>
                            <li class="p-terms-use__item">
                                当社のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為
                            </li>
                            <li class="p-terms-use__item">
                                その他、当社が不適切と判断する行為
                            </li>
                        </ol>

                        <div class="c-txt--l">第3条（シリアルコードの管理）</div>
                        <ol class="p-terms-use__list">
                            <li class="p-terms-use__item">
                                ユーザーは、自己の責任において、本サービスのシリアルコードを適切に管理するものとします。
                            </li>
                            <li class="p-terms-use__item">
                                ユーザーは、いかなる場合にも、シリアルコードを第三者に譲渡または貸与し、もしくは第三者と共用することはできません。
                            </li>
                            <li class="p-terms-use__item">
                                シリアルコードが第三者によって使用されたことによって生じた損害は、当社に故意又は重大な過失がある場合を除き、当社は一切の責任を負わないものとします。
                            </li>
                        </ol>

                        <div class="c-txt--l">第4条（本サービスの提供の停止等）</div>
                        <ol class="p-terms-use__list">
                            <li class="p-terms-use__item">
                                当社は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
                                <ol>
                                    <li>
                                        本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
                                    </li>
                                    <li>
                                        地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合
                                    </li>
                                    <li>
                                        コンピュータまたは通信回線等が事故により停止した場合
                                    </li>
                                    <li>
                                        その他、当社が本サービスの提供が困難と判断した場合
                                    </li>
                                </ol>
                            </li>
                            <li class="p-terms-use__item">
                                当社は、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害についても、一切の責任を負わないものとします。
                            </li>
                        </ol>

                        <div class="c-txt--l">第5条（保証の否認および免責事項）</div>
                        <ol class="p-terms-use__list">
                            <li class="p-terms-use__item">
                                当社は、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
                            </li>
                            <li class="p-terms-use__item">
                                当社は、本サービスに起因してユーザーに生じたあらゆる損害について、当社の故意又は重過失による場合を除き、一切の責任を負いません。ただし、本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合、この免責規定は適用されません。
                            </li>
                            <li class="p-terms-use__item">
                                前項ただし書に定める場合であっても、当社は、当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し、または予見し得た場合を含みます。）について一切の責任を負いません。
                            </li>
                            <li class="p-terms-use__item">
                                当社は、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。
                            </li>
                        </ol>

                        <div class="c-txt--l">第6条（サービス内容の変更等）</div>
                        <div class="p-terms-use__item">
                            当社は、ユーザーに通知することなく、本サービスの内容を変更、追加または廃止することがあり、ユーザーはこれを承諾するものとします。
                        </div>

                        <div class="c-txt--l">第7条（利用規約の変更）</div>
                        <div class="p-terms-use__item">
                            当社は、必要と判断した場合には、ユーザーの同意を要せず、本規約を変更することができるものとします。
                        </div>

                        <div class="c-txt--l">第8条（権利義務の譲渡の禁止）</div>
                        <div class="p-terms-use__item">
                            ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。
                        </div>

                        <div class="c-txt--l">第9条（準拠法・裁判管轄）</div>
                        <div class="p-terms-use__item">
                            本規約の解釈にあたっては、日本法を準拠法とします。<br>
                            本サービスに関して紛争が生じた場合には、当社の本社所在地を管轄する裁判所を専属的合意管轄とします。
                        </div>

                        <div class="c-txt--l">第10条（Cookie等について）</div>
                        <ol class="p-terms-use__list">
                            <li class="p-terms-use__item">
                                Cookieとは、ウェブサイトを利用したときに、ブラウザとサーバーとの間で送受信した利用履歴や入力内容などを、ユーザーおよび利用ユーザーのコンピュータにファイルとして保存しておく仕組みです。CookieとCookie同様の技術のことを、総称してCookie等といいます。次回、同じページにアクセスすると、Cookie等の情報を使って、サイトの運営者はユーザーおよび利用ユーザーごとに表示を変えたりすることができます。ユーザーおよび利用ユーザーがブラウザの設定でCookie等の送受信を許可している場合、ウェブサイトは、ユーザーおよび利用ユーザーのブラウザからCookie等を取得できます。Cookieに関する設定方法は、お使いのブラウザの「ヘルプ」メニューでご確認ください。なお、ユーザーおよび利用ユーザーのブラウザは、プライバシー保護のため、そのウェブサイトのサーバーが送受信したCookie等のみを送信します。
                            </li>
                            <li class="p-terms-use__item">
                                当社が閲覧履歴、行動履歴、購買履歴等の情報を参照するために、本サービス提供において利用ユーザーに対して発行するCookie等は、当該Cookie等の情報により、利用ユーザーを特定することはできません。
                            </li>
                        </ol>

                        <div class="c-txt--l">第11条（Cookie等の利用目的）</div>
                        <ol class="p-terms-use__list">
                            <li class="p-terms-use__item">
                                当社は、Cookie等を以下の目的で使用することがあります。
                                <ol>
                                    <li>
                                        サーバーで発生した障害や問題の原因を突き止め解決するため
                                    </li>
                                    <li>
                                        当社およびユーザーのサービスを提供、改善および開発するため
                                    </li>
                                    <li>
                                        Webサイトの内容を改良または個々の利用者向けにカスタマイズするため
                                    </li>
                                    <li>市場分析およびマーケティング活動のため</li>
                                    <li>
                                        個人を特定できない状態で統計資料として利用するため
                                    </li>
                                </ol>
                            </li>
                            <li class="p-terms-use__item">
                                ブラウザの設定でCookieの受け取りを拒否することにより、当社によるCookieの利用を拒否することができます。ただし、その場合には、一部の機能が使用できないなどの制約が生じることがあります。
                            </li>
                        </ol>

                        <div class="c-txt--l">第12条（アクセス情報の収集および利用について）</div>
                        <div class="p-terms-use__item">
                            本ウェブサイトでは、よりよいサービスの提供を目的として、Google社の提供するサービスであるGoogle
                            Analyticsを用いて、本ウェブサイトへのアクセス情報を解析しています。Google
                            Analyticsは、ファーストパーティCookieを用いて利用者の情報を個人が特定しない形で収集します。アクセス情報の収集方法および利用方法などについては、Google
                            Analyticsのデータのプライバシーとセキュリティをご覧ください。
                        </div>
                        <div class="p-terms-use__item">
                            Google Analyticsの利用規約<br>
                            <a href="https://marketingplatform.google.com/about/analytics/terms/jp/" class="c-txt--link" target="_blank">
                                https://marketingplatform.google.com/about/analytics/terms/jp/
                            </a>
                        </div>
                        <div class="p-terms-use__item">
                            Googleのプライバシーポリシー<br>
                            <a href="https://policies.google.com/privacy?hl=ja" class="c-txt--link" target="_blank">
                                https://policies.google.com/privacy?hl=ja
                            </a>
                        </div>
                    </div>
                </div>
                <div class="c-modal__foot">
                    <button type="button" class="c-btn c-btn--l c-btn--bg-primary c-txt--l modalClose" @click="$emit('close-term-modal')">
                        <span>閉じる</span>
                    </button>
                </div>
            </div>
        </div>

</template>
