<!--
    SET001_settings_textbook_first.vue
    学期制・教科書設定
-->
<script>
import axios from "axios";
import {
  API_URL_TEXTBOOK_COMPLIANCES,
  LS_KEY_TEXTBOX,
  LS_KEY_SELECTED_TERMS,
  LS_KEY_SELECTED_GRADES,
} from "../const.js";
import LastAccessDate from "../common/LastAccessDate.js";
import StorageExpiration from "@/common/StorageExpiration";
import Cookie from "../common/Cookie.js";
import Storage from "../common/Storage.js";
import HeaderArea from "./Header.vue";
import ErrorModal from "@/components/ErrorModal.vue";
import Methods from "@/common/Methods";

export default {
  components: {
    HeaderArea,
    ErrorModal,
  },
  data() {
    return {
      termType: "3",
      majorSubjects: [],
      minorSubjects: [],
      isOtherSubjectHidden: true,
    };
  },
  created() {
    Methods.checkMoveToTop() ? this.$router.push({ name: "FST001_first" }) : "";
    StorageExpiration.check();
    LastAccessDate.update();
    // 画面に表示する教科・学年・教科書準拠を取得
    axios
      .get(API_URL_TEXTBOOK_COMPLIANCES)
      .then((response) => {
        for (const subject of response.data.subjects) {
          subject.settingType = "1";
          subject.selectedCommonTextbookCompliance = "";

          for (const grade of subject.grades) {
            grade.selectedTextbookCompliance = "";
          }

          if (subject.isMajor) {
            this.majorSubjects.push(subject);
          } else {
            this.minorSubjects.push(subject);
          }
        }
      })
      .then(() => {
        // データ取得後、画面上部が表示されるよう調整
        window.scrollTo({
          top: 0,
          behavior: "instant"
        });
      })
      .catch((error) => {
        console.log(error);
        this.openErrorModal(error.response.status);
      });
  },
  computed: {
    /**
     * 各教科の全学年共通のセレクトボックスおよび学年毎のセレクトボックスのうち、
     * いずれか1つ以上「未選択」以外の値が選択されていれば保存ボタンを有効化する
     */
    cannotSave() {
      let isSelected = false;

      const subjects = this.majorSubjects.concat(this.minorSubjects);
      for (const subject of subjects) {
        const isCommon = subject.settingType === "1";

        if (isCommon) {
          // 全学年共通の場合
          const selectedTextbookCompliance = subject.selectedCommonTextbookCompliance;
          if (selectedTextbookCompliance !== "") {
            isSelected = true;
            break;
          }
        } else {
          // 学年毎の場合
          for (const grade of subject.grades) {
            if (grade.selectedTextbookCompliance !== "") {
              isSelected = true;
              break;
            }
          }
        }
      }
      return !isSelected;
    },
    /**
     * すべての教科を取得
     */
    allSubjects() {
      return this.majorSubjects.concat(this.minorSubjects);
    },
    /**
     * ブラウザに保存する教科書準拠データを取得
     */
    textbooks() {
      const textbooks = [];
      const subjects = this.allSubjects;

      // セットする教科書準拠・学期データを作成
      for (const subject of subjects) {
        const isCommon = subject.settingType === "1";

        // 学年ごとにループ
        for (const grade of subject.grades) {
          if (!grade.selectable) {
            continue;
          }

          // 教科書準拠データを作成
          if (isCommon) {
            // 全学年共通の場合
            textbooks.push({
              subject: subject.subjectName,
              settingType: subject.settingType,
              grade: grade.gradeName,
              textbook: subject.selectedCommonTextbookCompliance,
            });
          } else {
            // 学年毎の場合
            textbooks.push({
              subject: subject.subjectName,
              settingType: subject.settingType,
              grade: grade.gradeName,
              textbook: grade.selectedTextbookCompliance,
            });
          }
        }
      }
      return textbooks;
    },
    /**
     * ブラウザに保存する選択中の教科を取得
     */
    selectedSubject() {
      const textbooks = this.textbooks;

      for (const textbook of textbooks) {
        if (textbook.textbook !== "") {
          return textbook.subject;
        }
      }
      return "";
    },
    /**
     * ブラウザに保存する選択中の学期データを取得
     */
    selectedTermsList() {
      const termsList = [];
      const subjects = this.allSubjects;

      for (const subject of subjects) {
        if (this.termType === "3") {
          termsList.push({
            subject: subject.subjectName,
            terms: [1, 2, 3],
          });
        } else if (this.termType === "2") {
          termsList.push({
            subject: subject.subjectName,
            terms: [1, 2],
          });
        }
      }
      return termsList;
    },
    /**
     * ブラウザに保存する選択中の学年データを取得
     */
    selectedGradesList() {
      const gradesList = [];

      const textbooks = this.textbooks;
      for (const textbook of textbooks) {
        // 教科に一致する選択中の学年データを取得
        let selectedGrades = gradesList.find((target) => {
          return target.subject === textbook.subject;
        });

        // 教科に一致する選択中の学年データがない場合は空の学年配列で新規作成
        if (!selectedGrades) {
          selectedGrades = {
            subject: textbook.subject,
            grades: [],
          };
          gradesList.push(selectedGrades);
        }

        // 教科書準拠がセットされている学年を追加
        if (textbook.textbook !== "") {
          selectedGrades.grades.push(textbook.grade);
        }
      }
      return gradesList;
    },
  },
  methods: {
    /**
     * その他教科をトグル
     */
    toggleOtherSubject() {
      this.isOtherSubjectHidden = !this.isOtherSubjectHidden;
      // ボタン全体の要素を取得
      const btnMore = document.getElementsByClassName(
          "p-common-contents__body-all__body__btn-more"
      );
      if (this.isOtherSubjectHidden) {
        btnMore[0].children[0].textContent = "その他の教科も設定する";
        btnMore[0].classList.remove("is-open");
      } else {
        btnMore[0].children[0].textContent = "その他の教科を閉じる";
        btnMore[0].classList.add("is-open");

      }
    },
    /**
     * 保存ボタン押下時の処理
     */
    save() {
      // 学期制セット
      Cookie.set(Cookie.Key.TERM_TYPE, this.termType);

      // 教科書準拠セット
      const textbooks = this.textbooks;
      Storage.setJson(LS_KEY_TEXTBOX, textbooks);

      // 選択中の教科セット（教科書準拠が選択されている最初の教科）
      const selectedSubject = this.selectedSubject;
      Cookie.set(Cookie.Key.SELECTED_SUBJECT, selectedSubject);

      // 選択中の学期セット
      const selectedTermsList = this.selectedTermsList;
      Storage.setJson(LS_KEY_SELECTED_TERMS, selectedTermsList);

      // 選択中の学年セット（教科書準拠が設定されている学年を教科ごとにセット）
      const selectedGradesList = this.selectedGradesList;
      Storage.setJson(LS_KEY_SELECTED_GRADES, selectedGradesList);

      // 単元一覧に遷移
      this.$router.push({ name: "UNI001_units" });
    },

    /**
     * エラーモーダルを開く
     */
    openErrorModal(statusCode) {
      this.$refs.errorModal.open(statusCode);
    },
  },
};
</script>

<template>
  <div class="l-wrapper">
    <HeaderArea
      :logoRedFlag="false"
      :logoSideFlag="true"
      :menuFlag="false"
      @openErrorModal="openErrorModal"
    />

    <main class="setting">
      <div class="l-common-contents__asd-less">
        <div class="l-common-contents__head">
          <a
            href="#"
            class="p-common-contents__head__back-icon"
            @click="
              () =>
                $router.push({ name: 'FST002_settings_serial-number_first' })
            "
          >
            <img src="/assets/img/common/icon_arrow_black.svg" alt="" />
          </a>
          <div class="p-common-contents__head__title">基本設定</div>
        </div>
        <div class="l-common-contents__body-all" data-background="gray">
          <div class="p-common-contents__body-all__head">
            <div class="p-common-contents__body-all__head__title c-txt--xl">
              学期制設定
            </div>
            <p class="p-common-contents__body-all__head__txt c-txt--s">
              所属している学校の学期制を選択してください。
            </p>
            <div class="p-common-contents__body-all__head__container-btn">
              <label
                class="p-common-contents__body-all__head__btn c-txt--xs"
              >
                <input
                  type="radio"
                  v-model="termType"
                  value="3"
                />
                ３学期制
              </label>
              <label
                class="p-common-contents__body-all__head__btn c-txt--xs"
              >
                <input
                  type="radio"
                  v-model="termType"
                  value="2"
                />
                ２学期制
              </label>
            </div>
          </div>

          <div class="p-common-contents__body-all__body" id="settingContent">
            <div class="p-common-contents__body-all__body__title c-txt--xl">
              教科書設定
            </div>
            <p
              class="p-common-contents__body-all__body__txt-err c-txt--red c-txt--s"
            >
              ※ いずれか１科目の設定が必要です。
            </p>
            <p class="p-common-contents__body-all__body__txt c-txt--s">
              「設定」画面にて、後から変更が可能です。<br />
              学年によって教科書会社が異なる場合は、学年別に設定を行ってください。
            </p>
            <div class="p-common-contents__body-all__body__selects__container">
              <div
                class="p-common-contents__body-all__body__select__container"
                v-for="subject in majorSubjects"
                :key="subject.subjectName"
              >
                <div
                  class="p-common-contents__body-all__body__select__container-left"
                >
                  <span
                    class="p-common-contents__body-all__body__select-subject"
                  >
                    {{ subject.subjectName }}
                  </span>
                  <div class="p-common-contents__body-all__body__container-btn">
                    <label
                      class="p-common-contents__body-all__body__btn c-txt--xs"
                    >
                      <input
                        type="radio"
                        v-model="subject.settingType"
                        value="1"
                        class="js-selectUnity"
                      />
                      全学年共通
                    </label>
                    <label
                      class="p-common-contents__body-all__body__btn c-txt--xs"
                    >
                      <input
                        type="radio"
                        v-model="subject.settingType"
                        value="2"
                        class="js-selectSeparate"
                      />
                      学年毎に設定
                    </label>
                  </div>
                </div>
                <div
                  class="p-common-contents__body-all__body__select__container-right"
                >
                  <div
                    class="p-common-contents__body-all__body__book white js-unity"
                  >
                    <select
                      v-model="subject.selectedCommonTextbookCompliance"
                      class="c-txt--s"
                      @change="enableSaveButtonIfNeeded"
                    >
                      <option value="">未選択</option>
                      <option
                        v-for="textbookCompliance in subject.commonTextbookCompliances"
                        :key="textbookCompliance"
                        :value="textbookCompliance"
                      >
                        {{ textbookCompliance }}
                      </option>
                    </select>
                  </div>
                  <div
                    class="p-common-contents__body-all__body__container-books js-separate"
                  >
                    <div
                      class="p-common-contents__body-all__body__book white"
                      v-for="grade in subject.grades"
                      :key="grade.gradeName"
                    >
                      <span
                        class="p-common-contents__body-all__body__book-num c-txt--xxs"
                      >
                        {{ grade.gradeName }}
                      </span>
                      <select
                        v-if="grade.selectable"
                        v-model="grade.selectedTextbookCompliance"
                        class="c-txt--s"
                        @change="enableSaveButtonIfNeeded"
                      >
                        <option value="">未選択</option>
                        <option
                          v-for="textbookCompliance in grade.textbookCompliances"
                          :key="textbookCompliance"
                          :value="textbookCompliance"
                        >
                          {{ textbookCompliance }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="p-common-contents__body-all__body__select__container-others"
                v-show="!isOtherSubjectHidden"
              >
                <div
                  class="p-common-contents__body-all__body__select__container"
                  v-for="subject in minorSubjects"
                  :key="subject.subjectName"
                >
                  <div
                    class="p-common-contents__body-all__body__select__container-left"
                  >
                    <span
                      class="p-common-contents__body-all__body__select-subject"
                    >
                      {{ subject.subjectName }}
                    </span>
                    <div
                      class="p-common-contents__body-all__body__container-btn"
                    >
                      <label
                        class="p-common-contents__body-all__body__btn c-txt--xs"
                      >
                        <input
                          type="radio"
                          v-model="subject.settingType"
                          value="1"
                          class="js-selectUnity"
                        />
                        全学年共通
                      </label>
                      <label
                        class="p-common-contents__body-all__body__btn c-txt--xs"
                      >
                        <input
                          type="radio"
                          v-model="subject.settingType"
                          value="2"
                          class="js-selectSeparate"
                        />
                        学年毎に設定
                      </label>
                    </div>
                  </div>
                  <div
                    class="p-common-contents__body-all__body__select__container-right"
                  >
                    <div
                      class="p-common-contents__body-all__body__book white js-unity"
                    >
                      <select
                        v-model="subject.selectedCommonTextbookCompliance"
                        class="c-txt--s"
                        @change="enableSaveButtonIfNeeded"
                      >
                        <option value="">未選択</option>
                        <option
                          v-for="textbookCompliance in subject.commonTextbookCompliances"
                          :key="textbookCompliance"
                          :value="textbookCompliance"
                        >
                          {{ textbookCompliance }}
                        </option>
                      </select>
                    </div>
                    <div
                      class="p-common-contents__body-all__body__container-books js-separate"
                    >
                      <div
                        class="p-common-contents__body-all__body__book white"
                        v-for="grade in subject.grades"
                        :key="grade.gradeName"
                      >
                        <span
                          class="p-common-contents__body-all__body__book-num c-txt--xxs"
                        >
                          {{ grade.gradeName }}
                        </span>
                        <select
                          v-if="grade.selectable"
                          v-model="grade.selectedTextbookCompliance"
                          class="c-txt--s"
                          @change="enableSaveButtonIfNeeded"
                        >
                          <option value="">未選択</option>
                          <option
                            v-for="textbookCompliance in grade.textbookCompliances"
                            :key="textbookCompliance"
                            :value="textbookCompliance"
                          >
                            {{ textbookCompliance }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-common-contents__body-all__body__btn-more"
            @click="toggleOtherSubject()">
              <button
                type="button"
                class="c-txt--xs"
              >
                その他の教科も設定する
              </button>
            </div>
          </div>
        </div>
        <div class="l-common-contents__foot">
          <div class="p-common-contents__shingle-btn">
            <div class="c-btn-size">
              <button
                type="submit"
                @click="save()"
                class="c-btn c-btn--l c-btn--bg-primary c-txt--l"
                :class="{ disabled: cannotSave }"
                id="settingBtn"
              >
                <span>設定して開始</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- [/l-contents] -->

    <ErrorModal ref="errorModal" />
  </div>
  <!-- [/l-wrapper] -->
</template>
